// prettier-ignore
export const routes = {
  main: () => `/`,

  transactions: () => `/transactions`,

  transaction: ($: { id: string }) => `/transactions/${$.id}`,

  settings: () => `/settings`,

  deposit: {
    currency: () => `/deposit`,

    index: ($: { currency: string }) => `/deposit/${$.currency}`,

    provider: ($: { provider: string }) => `/deposit/amount/${$.provider}`,

    confirm: ($: { provider: string; amount: string; href: string }) =>
      `/deposit/confirm/${$.provider}/${$.amount}/${$.href}`,

    amount: ($: { currency: string }) => `/deposit/amount/${$.currency}`,

    status: ($: { currency: string; value: string }) => `/deposit/status/${$.currency}/${$.value}`,
  },

  depositTonConnect: {
    amount: ($: { currency: string }) => `/deposit/ton-connect/amount/${$.currency}`,
  },

  withdraw: {
    address: ($: { currency: string }) => `/withdraw/address/${$.currency}`,

    amount: ($: { currency: string; amount: string; address: string; memo?: string }) =>
      `/withdraw/ton-connect/amount/${$.currency}/${$.amount}/${$.address}`,

    confirm: ($: {
      currency: string;
      address: string;
      fee: string;
      amount: string;
      memo?: string;
    }) => `/withdraw/confirm/${$.currency}/${$.amount}/${$.address}/${$.fee}`,

    success: ($: { currency: string; address: string; amount: string; memo?: string }) =>
      `/withdraw/success/${$.currency}/${$.address}/${$.amount}`,
  },

  withdrawTonConnect: {
    amount: ($: { amount: string; currency: string }) =>
      `/withdraw/ton-connect/${$.currency}/${$.amount}`,

    confirm: ($: { fee: string; amount: string; currency: string }) =>
      `/withdraw/ton-connect/confirm/${$.currency}/${$.fee}/${$.amount}`,

    success: ($: { currency: string; amount: string }) =>
      `/withdraw/ton-connect/success/${$.currency}/${$.amount}`,
  },

  buy: {
    amount: () => `/buy/amount`,

    bank: ($: { id: string }) => `/buy/${$.id}/bank`,

    payment: ($: { id: string }) => `/buy/${$.id}/payment`,

    confirmation: ($: { id: string }) => `/buy/${$.id}/confirmation`,

    status: ($: { id: string }) => `/buy/${$.id}/status`,

    dispute: ($: { id: string }) => `/buy/${$.id}/dispute`,

    transaction: ($: { id: string }) => `/buy/${$.id}/transaction`,

    externalPayment: ($: { id: string }) => `/buy/${$.id}/external-payment`,

    externalDetails: ($: { id: string }) => `/buy/${$.id}/external-details`,
  },

  sell: {
    amount: () => `/sell`,

    bank: ($: { id: string }) => `/sell/${$.id}/bank`,

    payment: ($: { id: string }) => `/sell/${$.id}/payment`,

    confirm: ($: { id: string }) => `/sell/${$.id}/confirm`,

    status: ($: { id: string }) => `/sell/${$.id}/status`,

    externalDetails: ($: { id: string }) => `/sell/${$.id}/external-details`,
  },
};
