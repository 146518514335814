import styled from "styled-components";
import { hintSkeleton } from "styled-mixins/skeleton";
import { render } from "lib/render";
import { transparentize } from "polished";

import { Balance } from "models";
import { ReactComponent as BalanceChangeIcon } from "icons/right2.svg";

import { useTranslation } from "services/i18n";

type Props = {
  balance?: Balance;
  onClick?(): void;
};

export function BalanceView({ balance, onClick }: Props) {
  const t = useTranslation();

  const value = balance
    ? spaces(Number(balance.fiat.value).toFixed(balance.fiat.currency.precision))
    : "0.00";

  const currency = balance ? render(balance.fiat.currency.displayFormat, { amount: "" }) : "$";

  return (
    <BalanceContainer onClick={onClick}>
      {balance && (
        <BalanceForeground>
          {render(balance.fiat.currency.displayFormat, { amount: "" })}
        </BalanceForeground>
      )}
      <BalancePrimary>
        <BalancePrimaryValue>
          {balance ? value : <TextSkeleton>{value}</TextSkeleton>}
        </BalancePrimaryValue>
        <BalancePrimaryCurrency>
          {balance ? currency : <TextSkeleton>{currency}</TextSkeleton>}
        </BalancePrimaryCurrency>
      </BalancePrimary>
      <BalanceLabel>
        {t("ui", "wallet_currency_edit")}
        <BalanceChangeIcon />
      </BalanceLabel>
    </BalanceContainer>
  );
}

function spaces(value: string): string {
  const [dec, frac] = value.split(".");
  const decArr = Array.from(dec).reverse();

  const parts = [];
  for (let i = 0; i < decArr.length; i += 3) {
    parts.unshift(
      decArr
        .slice(i, i + 3)
        .reverse()
        .join(""),
    );
  }
  return parts.join(" ") + (frac === undefined ? "" : "." + frac);
}

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  cursor: pointer;

  padding: 16px;

  gap: 8px;

  border-radius: 20px;
  border: 1px solid ${props => transparentize(0.8, props.theme.button)};
  background: ${props => transparentize(0.9, props.theme.button)};
`;

const BalanceLabel = styled.div`
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;

  color: ${props => props.theme.link};

  svg {
    width: 16px;
    height: 16px;
  }
`;

const BalancePrimary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  font-family: "Nunito", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 800;

  text-align: center;
  letter-spacing: -0.02em;

  flex-wrap: wrap;

  border-radius: 10px;

  cursor: pointer;

  position: relative;

  font-size: 2rem;
  line-height: 2rem;
`;

const BalancePrimaryValue = styled.div`
  color: ${props => props.theme.text};

  white-space: nowrap;
`;

const BalancePrimaryCurrency = styled(BalancePrimaryValue)`
  color: ${props => props.theme.hint};
  margin-left: 6px;
`;

const TextSkeleton = styled.span`
  ${hintSkeleton}
`;

const BalanceForeground = styled.div`
  position: absolute;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Nunito, "Helvetica Neue", sans-serif;
  font-size: 186px;
  font-weight: 800;
  line-height: 15px;

  color: ${props => transparentize(0.95, props.theme.button)};

  overflow: hidden;
`;
