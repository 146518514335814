import styled from "styled-components";
import { hintSkeleton } from "styled-mixins/skeleton";
import { replicate } from "lib/replicate";
import { cutAddress } from "lib/cut-address";

import { Transaction, Currency, formatAmount } from "models";

import { useTranslation } from "services/i18n";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import { List, ListItemLayout } from "components/list";
import { IconFrame } from "components/icon-frame";

import { ReactComponent as DepositIcon } from "icons/actions/deposit-rounded.svg";
import { ReactComponent as WithdrawIcon } from "icons/actions/withdraw-rounded.svg";
import { ReactComponent as SellIcon } from "icons/actions/sell.svg";
import { ReactComponent as BuyIcon } from "icons/actions/buy.svg";
import { useEffect } from "react";
import { useState } from "../../hooks/use-state";

type Props = {
  transactions: null | Transaction[];
  onClick(transaction: Transaction): void;
  onCancel(transaction: Transaction): Promise<void>;
};

export function TabTransactions({ transactions, onClick, onCancel }: Props) {
  const [ref] = useAutoAnimate();

  if (transactions === null) {
    return (
      <TransactionsContainer>
        <List>
          {replicate(3).map(i => (
            <TransactionLayout
              key={i}
              icon={<IconSkeleton />}
              title={<TextSkeleton>Loading: address</TextSkeleton>}
              date={<TextSkeleton>Month day at time</TextSkeleton>}
              value={<TextSkeleton>+value TON</TextSkeleton>}
              status={<TextSkeleton>Status</TextSkeleton>}
              success={false}
              failure={false}
              cancelable={false}
              canceling={false}
              onClick={() => {}}
              onCancel={() => {}}
            />
          ))}
        </List>
      </TransactionsContainer>
    );
  } else if (transactions.length === 0) {
    return <></>;
  } else {
    return (
      <TransactionsContainer>
        <List ref={ref}>
          {transactions.map(transaction => (
            <TransactionView
              key={transaction.id}
              transaction={transaction}
              onClick={() => onClick(transaction)}
              onCancel={() => onCancel(transaction)}
            />
          ))}
        </List>
      </TransactionsContainer>
    );
  }
}

function TransactionView({
  transaction,
  onClick,
  onCancel,
}: {
  transaction: Transaction;
  canceling?: boolean;
  onClick(): void;
  onCancel(): Promise<void>;
}) {
  const t = useTranslation();
  const cancelable = useState(() => +new Date(transaction.cancellableUntil ?? 0) > Date.now());
  const canceling = useState(false);

  useEffect(() => {
    if (!cancelable.get) return;

    const canBeCanceledIn = +new Date(transaction.cancellableUntil ?? 0) - Date.now();

    const timeout = setTimeout(() => {
      cancelable.set(false);
    }, canBeCanceledIn);

    return () => {
      clearTimeout(timeout);
    };
  }, [cancelable.get]);

  const icon =
    transaction.type === "withdraw" ? (
      <WithdrawIcon />
    ) : transaction.type === "deposit" ? (
      <DepositIcon />
    ) : transaction.type === "sell" ? (
      <SellIcon />
    ) : transaction.type === "buy" ? (
      <BuyIcon />
    ) : (
      (transaction.type satisfies never)
    );

  const title =
    transaction.type === "withdraw"
      ? t("ui", "wallet_action_withdraw") +
        (transaction.address ? ": " + cutAddress(transaction.address) : "")
      : transaction.type === "deposit"
        ? t("ui", "wallet_action_deposit") +
          (transaction.address ? ": " + cutAddress(transaction.address) : "")
        : transaction.type === "buy"
          ? t("ui", "wallet_action_buy")
          : transaction.type === "sell"
            ? t("ui", "wallet_action_sell")
            : (transaction.type satisfies never);

  const date = t(transaction.timestamp);

  const value =
    (transaction.type === "withdraw" || transaction.type === "sell" ? "" : "+") +
    formatAmount(transaction.amount.value, transaction.amount.currency);

  const status =
    transaction.status === "pending"
      ? t("ui", "wallet_transaction_status_pending")
      : transaction.status === "declined"
        ? t("ui", "wallet_transaction_status_declined")
        : transaction.status === "completed"
          ? transaction.type === "withdraw" || transaction.type === "sell"
            ? t("ui", "wallet_transaction_status_sent")
            : transaction.type === "deposit" || transaction.type === "buy"
              ? t("ui", "wallet_transaction_status_received")
              : (transaction.type satisfies never)
          : transaction.status === "waiting"
            ? t("ui", "wallet_transaction_status_waiting")
            : transaction.status === "timeouted"
              ? t("ui", "wallet_transaction_status_timeouted")
              : transaction.status === "refund"
                ? t("ui", "wallet_transaction_status_refund")
                : transaction.status === "disputed"
                  ? t("ui", "wallet_transaction_status_disputed")
                  : (transaction.status satisfies never);

  const success = transaction.status === "completed" || transaction.status === "refund";
  const failure = transaction.status === "declined" || transaction.status === "timeouted";

  return (
    <TransactionLayout
      icon={<IconFrame>{icon}</IconFrame>}
      currency={transaction.amount.currency}
      title={title}
      date={date}
      value={value}
      status={status}
      success={success}
      failure={failure}
      cancelable={cancelable.get}
      canceling={canceling.get}
      onClick={onClick}
      onCancel={async () => {
        try {
          canceling.set(true);
          await onCancel();
          cancelable.set(false);
        } finally {
          canceling.set(false);
        }
      }}
    />
  );
}

function TransactionLayout({
  icon,
  currency,
  title,
  date,
  value,
  status,
  success,
  failure,
  cancelable,
  canceling,
  onClick,
  onCancel,
}: Record<"icon" | "title" | "date" | "value" | "status", React.ReactNode> & {
  currency?: Currency;
  success: boolean;
  failure: boolean;
  cancelable: boolean;
  canceling: boolean;
  onClick(): void;
  onCancel(): void;
}) {
  const t = useTranslation();

  return (
    <>
      <ListItemLayout
        onClick={onClick}
        icon={icon}
        badge={currency?.icon}
        title={
          <TransactionLine>
            <TransactionTitle>{title}</TransactionTitle>
            <TransactionValue success={success} failure={failure}>
              {value}
            </TransactionValue>
          </TransactionLine>
        }
        description={
          <TransactionLine>
            <TransactionDate>{date}</TransactionDate>
            <TransactionStatus success={success} failure={failure}>
              {status}
            </TransactionStatus>
          </TransactionLine>
        }
        under={
          cancelable && (
            <CancelButton
              onClick={
                canceling
                  ? undefined
                  : async event => {
                      event.stopPropagation();
                      return onCancel();
                    }
              }
              disabled={canceling}
            >
              {t("ui", "wallet_order_cancel")}
            </CancelButton>
          )
        }
      />
    </>
  );
}

const TransactionsContainer = styled.div``;

const TransactionLine = styled.div`
  width: 100%;

  display: flex;

  @media (max-width: 300px) {
    flex-direction: column;
  }
`;

const TransactionTitle = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const TransactionDate = styled(TransactionTitle)``;

const TransactionValue = styled.span<{ success?: boolean; failure?: boolean }>`
  flex-grow: 1;
  padding-left: 5px;
  text-align: right;

  @media (max-width: 300px) {
    text-align: left;
    padding-left: 0;
  }

  color: ${props =>
    props.success ? props.theme.success : props.failure ? props.theme.error : "inherit"};
`;

const TransactionStatus = styled(TransactionValue)``;

const TextSkeleton = styled.span`
  ${hintSkeleton}
`;

const IconSkeleton = styled.div`
  ${hintSkeleton};
  width: 100%;
  height: 100%;
  border-radius: 100%;
`;

const CancelButton = styled.button`
  margin: 0;

  padding: 10px;
  width: 100%;

  border-radius: 100px;
  background: #ff492d;
  color: ${props => props.theme.buttonText};

  box-shadow:
    0 8px 16px -4px rgba(15, 17, 33, 0.2),
    0 -1px 0 0 rgba(0, 0, 0, 0.16) inset,
    0 1px 0 0 rgba(255, 255, 255, 0.16) inset;

  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  border: 0;
  cursor: pointer;

  &:disabled {
    cursor: wait;
    opacity: 0.5;
  }
`;
