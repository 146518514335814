import styled, { css } from "styled-components";
import { transparentize } from "polished";
import { hintSkeleton } from "../../styled-mixins/skeleton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ListContainer = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? "none" : "flex")};
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.div`
  padding-left: 6px;

  color: ${props => props.theme.hint};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.24px;
  text-transform: uppercase;
`;

const PayWithCryptoList = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 0;

  margin-left: -4px;
  margin-right: -4px;
  border-radius: 20px;
  background: ${props => transparentize(0.9, props.theme.button)};

  overflow: hidden;
`;

const PayWithCryptoItem = styled.button`
  display: grid;
  grid-template:
    "icon name bonus"
    "icon desc bonus"
    / min-content 1fr;
  column-gap: 10px;

  align-items: center;

  border: 0;
  padding: 8px 16px;
  margin: 0;
  background: none;
  text-align: left;

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;

      &:hover {
        background: ${props => transparentize(0.9, props.theme.button)};
      }
    `}
  &:not(:last-child) {
    border-bottom: 1px solid var(--dark-button-color-opacity-10, rgba(85, 164, 248, 0.1));
  }
`;

const PayWithCryptoIcon = styled.img`
  grid-area: icon;
  width: 40px;
  height: 40px;
`;

const PayWithCryptoIconSkeleton = styled.div`
  grid-area: icon;
  width: 40px;
  height: 40px;

  ${hintSkeleton};

  border-radius: 100%;
`;

const PayWithCryptoName = styled.div`
  grid-area: name;

  color: ${props => props.theme.text};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3125rem;
  letter-spacing: 0.32px;
`;

export const PayWithCryptoBonus = styled.div`
  grid-area: bonus;

  color: ${props => props.theme.text};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 140%;

  padding: 4px 8px;
  border-radius: 1000px;
  background: linear-gradient(
    92deg,
    #6b40ea 0%,
    #6558ee 19.92%,
    #5c6cf3 39.84%,
    #4f7ff7 59.76%,
    #3a91fb 79.68%,
    #00a3ff 99.6%
  );
`;

const PayWithCryptoDesc = styled.div`
  grid-area: desc;

  color: ${props => props.theme.hint};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.28px;
`;

const TextSkeleton = styled.span`
  ${hintSkeleton};
`;

export {
  Container,
  ListContainer,
  Label,
  PayWithCryptoIcon,
  PayWithCryptoIconSkeleton,
  PayWithCryptoDesc,
  PayWithCryptoName,
  PayWithCryptoList,
  PayWithCryptoItem,
  TextSkeleton,
};
