import styled, { createGlobalStyle, css, keyframes } from "styled-components";

import MethodOverlay from "icons/deposit-method-overlay.svg";
import { ReactComponent as DepositCryptoIcon } from "icons/deposit-method-crypto.svg";
import { ReactComponent as DepositCardIcon } from "icons/deposit-method-card.svg";
import { ReactComponent as RightIcon } from "icons/right.svg";
import FirstDepositIcon from "icons/first-deposit-bonus-logo.svg";

import { useTranslation } from "services/i18n";
import { useState } from "../hooks/use-state";
import { Fragment, useEffect, useMemo, useRef } from "react";
import { Bonus } from "../models";
import { useBalance } from "../services/balance";
import { BuyAmountView } from "./buy/amount";
import { usePromise } from "../hooks/use-promise";
import { reroute } from "./buy/reroute";
import { routes } from "../routes";
import { useApiService } from "../services/api";
import { useNavigate } from "../hooks/use-navigate";
import { useNotificationsService } from "../services/notifications";
import { BuyBankPageSkeleton } from "./buy/bank";
import { BonusStoriesPage } from "./bonus-stories";
import { useCurrenciesService, useOrderCurrency } from "../services/currencies";
import { customScrollbars } from "../styled-mixins/custom-scrollbar";

type BonusesViewProps = {
  bonuses: Bonus[] | undefined;
  onContinueWithBonuses(): void;
  onContinueWithoutBonuses(): void;
};

export function BonusesView(props: BonusesViewProps) {
  const t = useTranslation();

  const textRef = useRef<HTMLDivElement>(null);
  const active = useState<Bonus["id"] | null>(null);

  useEffect(() => {
    if (active.get) {
      textRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [active.get]);

  return (
    <Container>
      <GlobalStyles />
      <Header style={{ paddingBottom: 16 }}>
        <HeaderIcon src={FirstDepositIcon} alt={"jetton"} />
        <HeaderText>{t("ui", "casino_available_bonuses_header")}</HeaderText>
      </Header>
      <BonusesContainer>
        {props.bonuses?.map(bonus => {
          const isActive = active.get === bonus.id;

          return (
            <Fragment key={bonus.id}>
              <BonusContainer
                $active={isActive}
                onClick={() => active.set(isActive ? null : bonus.id)}
              >
                <BonusTitle>{bonus.title}</BonusTitle>
                <BonusMore>{t("ui", "bonuses_show_more")}</BonusMore>
                <BonusExpander $active={isActive} />
              </BonusContainer>
              {isActive && (
                <BonusText dangerouslySetInnerHTML={{ __html: bonus.description }} ref={textRef} />
              )}
            </Fragment>
          );
        })}
      </BonusesContainer>
      <ContinueButton onClick={props.onContinueWithBonuses}>
        {t("ui", "bonuses_continue")}
      </ContinueButton>
      <NoBonusesButton onClick={props.onContinueWithoutBonuses}>
        {t("ui", "bonuses_without")}
      </NoBonusesButton>
    </Container>
  );
}

export type DepositViewProps = {
  onCrypto(): void;
  onFiat(): void;
};

export function DepositView(props: DepositViewProps) {
  const t = useTranslation();
  const api = useApiService();
  const notifications = useNotificationsService();
  const navigate = useNavigate();
  const balance = useBalance();
  const currenciesService = useCurrenciesService();

  const valueFrom = useState(0);
  const submitting = useState(false);
  const hideBuy = useState(() =>
    balance
      ? !currenciesService.orderCurrencies.value.find(c => c.code === balance.fiat.currency.code)
      : false,
  );

  const makeDirty = useRef<() => void>();

  const order = usePromise(() => api.createBuyOrder(), [balance?.fiat.currency.code]);
  useEffect(() => {
    if (order.value && order.value.status !== "enter_amount") {
      navigate(reroute(order.value));
    }

    if (order.error) {
      hideBuy.set(true);
    }
  }, [order.value, order.error?.message]);

  const canSubmit =
    !submitting.get &&
    order.value &&
    valueFrom.get >= order.value.minAmount &&
    valueFrom.get <= order.value.maxAmount;

  const presets = useOrderCurrency(order.value?.currencyFrom?.code)?.valuePresets;

  async function handleSubmit() {
    if (
      balance &&
      !currenciesService.orderCurrencies.value.find(c => c.code === balance.fiat.currency.code)
    ) {
      notifications.notify({
        title: t("ui", "error_currency_not_supported_title", {
          currency: balance?.fiat.currency.code,
        }),
        message: t("ui", "error_currency_not_supported_message"),
        buttons: currenciesService.orderCurrencies.value
          .map(c => c.code)
          .map((currency: string) => ({
            label: currency,
            onClick() {
              void api.setActiveCurrency({ primary: currency });
            },
          })),
      });

      return;
    }

    if (!canSubmit) {
      makeDirty.current?.();
      return;
    }

    try {
      submitting.set(true);
      const newOrder = await api.setBuyOrderAmount(
        order.value.id,
        valueFrom.get,
        window.location.origin + routes.buy.status(order.value),
      );

      navigate(reroute(newOrder));
    } catch (err) {
      notifications.notify({
        message: t("errors", "wallet_order_error"),
      });
    } finally {
      submitting.set(false);
    }
  }

  if (submitting.get) {
    return <BuyBankPageSkeleton />;
  }

  return (
    <Container style={{ justifyContent: hideBuy.get ? "center" : "flex-start" }}>
      <GlobalStyles />

      {!hideBuy.get && (
        <BuyContainer>
          <BuyAmountView
            valueFrom={valueFrom.get}
            onFromChange={valueFrom.set}
            valueTo={0}
            bonuses={balance?.availableBonuses}
            order={order.value}
            presets={presets}
            dirtySignal={makeDirty}
          />
        </BuyContainer>
      )}
      <DepositHeader>{t("ui", "casino_new_deposit_header")}</DepositHeader>

      <Methods>
        <Method onClick={handleSubmit}>
          <DepositCardIcon />
          <MethodName>{t("ui", "casino_new_deposit_card")}</MethodName>
          <RightIcon />
        </Method>

        <Method onClick={props.onCrypto}>
          <DepositCryptoIcon />
          <MethodName>{t("ui", "casino_new_deposit_crypto")}</MethodName>
          <RightIcon />
        </Method>
      </Methods>
    </Container>
  );
}

enum Page {
  // Bonuses,
  Deposit,
  Stories,
}

export function FirstDepositView(props: DepositViewProps) {
  const page = useState(Page.Deposit);
  const balance = useBalance();
  const bonuses = balance?.availableBonuses?.filter(_ => _.isFirst);

  useEffect(() => {
    if (bonuses?.length === 0) {
      page.set(Page.Deposit);
    }
  }, [bonuses?.length]);

  // useTelegramBackButton(
  //   page.get === Page.Deposit && (bonuses?.length ?? 0) > 0
  //     ? {
  //       text: t("ui", "back"),
  //       onClick() {
  //         page.set(Page.Bonuses);
  //       },
  //     }
  //     : undefined,
  // );

  const stories = useMemo(() => {
    return <BonusStoriesPage onComplete={() => page.set(Page.Deposit)} />;
  }, []);

  switch (page.get) {
    // case Page.Bonuses:
    //   return (
    //     <BonusesView
    //       // onContinueWithBonuses={() => page.set(Page.Stories)}
    //       onContinueWithBonuses={() => page.set(Page.Deposit)}
    //       onContinueWithoutBonuses={() => page.set(Page.Deposit)}
    //       bonuses={bonuses}
    //     />
    //   );
    case Page.Deposit:
      return <DepositView {...props} />;
    case Page.Stories:
      return stories;
    default:
      return page.get satisfies never;
  }
}

const GlobalStyles = createGlobalStyle`
    body, html {

    }
`;

const Container = styled.div`
  background:
    linear-gradient(180deg, #18204a 0%, rgba(24, 32, 74, 0.8) 100%),
    url("/new-deposit-background.webp"),
    lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;

  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  margin: -16px -20px;
`;

const Header = styled.div`
  padding: 32px;

  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 39.2px */
  text-transform: uppercase;

  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const HeaderIcon = styled.img``;
const HeaderText = styled.div``;

const Methods = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 32px;
  gap: 12px;
`;

const Method = styled.button`
  margin: 0;
  padding: 16px;
  text-align: left;

  border: 0;
  border-radius: 16px;

  background: linear-gradient(
    92deg,
    #6b40ea 0%,
    #6558ee 19.92%,
    #5c6cf3 39.84%,
    #4f7ff7 59.76%,
    #3a91fb 79.68%,
    #00a3ff 99.6%
  );

  box-shadow:
    0 8px 24px 0 rgba(8, 122, 255, 0.24),
    rgba(255, 255, 255, 0.1) 0 2px 0 0 inset,
    rgba(0, 0, 0, 0.16) 0 -1px 0 0 inset;

  align-items: center;

  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
  letter-spacing: -0.2px;

  display: flex;
  flex-direction: row;
  gap: 16px;

  position: relative;

  overflow: hidden;

  cursor: pointer;

  filter: brightness(1);
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(1.2);
  }

  &::after {
    content: "";
    display: block;

    position: absolute;
    right: 60px;
    top: 0;
    bottom: 0;
    width: 300px;
    background: url("${MethodOverlay}") no-repeat center right;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const MethodName = styled.div`
  flex-grow: 1;
`;

const ContinueButton = styled.button`
  border: 0;

  margin: 32px;
  margin-bottom: 0;
  padding: 16px;

  border-radius: 100px;
  background: linear-gradient(84deg, #6b40ea -0.06%, #00a3ff 99.94%);
  box-shadow:
    0 1px 0 0 rgba(255, 255, 255, 0.04) inset,
    0 8px 8px -4px rgba(15, 17, 33, 0.08);

  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;

  cursor: pointer;
`;

const NoBonusesButton = styled.div`
  padding: 16px;
  margin-bottom: 16px;

  color: #9ea8dd;

  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;

  cursor: pointer;

  align-self: center;
`;

const BonusesContainer = styled.div`
  overflow-y: auto;
  padding: 16px;
`;

const BonusContainer = styled.div<{ $active: boolean }>`
  padding: 16px;

  display: grid;
  grid-template:
    "title expand"
    "more expand"
    / 1fr max-content;

  row-gap: 8px;
  align-items: center;

  cursor: pointer;
  border-radius: 16px;

  &:hover {
    background: rgba(101, 88, 238, 0.2);
  }

  ${props =>
    props.$active &&
    css`
      box-shadow: 0 8px 24px 0 rgba(8, 122, 255, 0.24);
      background:
        url("${MethodOverlay}") no-repeat center right,
        linear-gradient(
          92deg,
          #6b40ea 0%,
          #6558ee 19.92%,
          #5c6cf3 39.84%,
          #4f7ff7 59.76%,
          #3a91fb 79.68%,
          #00a3ff 99.6%
        ) !important;
      background-size: contain !important;
    `};
`;

const BonusTitle = styled.div`
  grid-area: title;

  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 120%; /* 24px */
  letter-spacing: -0.2px;
`;

const BonusMore = styled.div`
  grid-area: more;

  color: rgba(255, 255, 255, 0.64);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.14px;
`;

const BonusExpander = styled(RightIcon)<{ $active: boolean }>`
  grid-area: expand;
  transition: transform 200ms ease-in-out;
  transform: rotate(${props => (props.$active ? -90 : 90)}deg);
`;

const BonusText = styled.div`
  padding: 4px 16px;
  border-radius: 16px;
  background: #323c77;
  color: #fff;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 5px 0;
  }

  max-height: 180px;
  overflow-y: auto;
  ${customScrollbars};

  animation: 200ms linear ${keyframes`
        from {
            opacity: 0;
            max-height: 0
        }
        to {
            opacity: 1;
            max-height: 180px;
        }
    `};

  scroll-margin-top: 100px;

  font-size: 13px;
`;

const BuyContainer = styled.div`
  padding: 12px;
`;

const DepositHeader = styled.div`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;

  padding: 16px;
`;
