let canvas: CanvasRenderingContext2D = document.createElement("canvas").getContext("2d") as any;

type Font = string;

export function textWidth(text: string, font: string): number {
  canvas.font = font;
  const metrics = canvas.measureText(text);
  return metrics.width;
}

export function elementFont(element: HTMLElement): Font {
  const style = window.getComputedStyle(element, null);
  const weight = style.getPropertyValue("font-weight");
  const size = style.getPropertyValue("font-size");
  const family = style.getPropertyValue("font-family");
  return `${weight} ${size} ${family}`;
}
