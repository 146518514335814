import styled from "styled-components";

type Props = {
  icon?: React.ReactNode;
  children: React.ReactNode;
};

export function CentralHeader(props: Props) {
  return (
    <Container>
      {props.icon && <Icon>{props.icon}</Icon>}
      {typeof props.children === "string" ? (
        <Label dangerouslySetInnerHTML={{ __html: props.children }} />
      ) : (
        <Label>{props.children}</Label>
      )}
    </Container>
  );
}

const Container = styled.div`
  margin: 16px 0;

  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.div`
  flex-shrink: 0;

  width: 20px;
  height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  font-size: 0;
  color: ${props => props.theme.buttonText};
  background-color: ${props => props.theme.button};
`;

const Label = styled.div`
  color: ${props => props.theme.text};
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.28px;
  text-align: center;

  max-width: 300px;

  span {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 999px;
    background: var(
      --gradient-blue,
      linear-gradient(
        92deg,
        #6b40ea 0%,
        #6558ee 19.92%,
        #5c6cf3 39.84%,
        #4f7ff7 59.76%,
        #3a91fb 79.68%,
        #00a3ff 99.6%
      )
    );
  }
`;
