import styled, { createGlobalStyle } from "styled-components";
import { routes } from "routes";
import { replicate } from "lib/replicate";
import { Bank } from "models";

import { BuyOrder } from "shared/models/buy";
import { useNavigate } from "hooks/use-navigate";
import { useParam } from "hooks/use-params";
import { usePromise } from "hooks/use-promise";
import { useState } from "hooks/use-state";
import { useApiService } from "services/api";
import { useTelegramBackButton } from "services/telegram";
import { useNotificationsService } from "services/notifications";
import { useTranslation } from "services/i18n";
import { useTrack } from "services/track";
import { useOrder } from "hooks/use-order";

import { OrderAmount } from "components/order-amount";
import { SelectList, SelectListOption, SelectListSkeleton } from "components/select-list";
import { BankLogo } from "components/bank-logo";
import { reroute } from "./reroute";
import { Loader } from "../../components/loader";

const validOrderStatuses = ["select_bank"] as const satisfies readonly BuyOrder["status"][];

export function BuyBankPage() {
  const t = useTranslation();
  const track = useTrack();
  const navigate = useNavigate();
  const api = useApiService();
  const notifications = useNotificationsService();

  const orderId = useParam("id");
  const order = useOrder(orderId, validOrderStatuses, []);
  const submitting = useState(false);

  const banks = usePromise(() => api.getBuyOrderBanks(orderId).then(banks => banks), [orderId]);

  const canSubmit = order && banks.value;

  async function handleSubmit(bank: Bank) {
    try {
      if (!canSubmit) return;
      submitting.set(true);
      const newOrder = await api.setBuyOrderBank(order.id, bank);
      track("[Wallet] Buy: bank selected", { bank: bank.name });
      navigate(reroute(newOrder));
    } catch (err) {
      notifications.notify({ message: t("errors", "wallet_order_error") });
      submitting.set(false);
    }
  }

  useTelegramBackButton({
    text: t("ui", "wallet_back_button_amount"),
    disabled: submitting.get,
    onClick() {
      if (order) {
        track("[Wallet] Buy: back to amount");
        navigate(routes.buy.amount());
      }
    },
  });

  if (submitting.get) {
    return <Loader />;
  }

  return <BuyBankView onSelect={handleSubmit} order={order} banks={banks.value} />;
}

type Props = {
  onSelect(bank: Bank): void;

  order?: BuyOrder & { status: (typeof validOrderStatuses)[number] };
  banks?: Bank[];
};

export function BuyBankView(props: Props) {
  const t = useTranslation();

  return (
    <Container>
      <Global />

      <OrderAmount
        value={props.order?.valueFrom}
        currency={props.order?.currencyFrom}
        label={"wallet_buy_will_pay"}
      />
      <SelectList header={t("ui", "wallet_buy_bank_header")}>
        {props.banks === undefined
          ? replicate(5).map(i => (
              <SelectListSkeleton key={i}>
                some bank {Array.from({ length: Math.random() * 30 }).toString()}
              </SelectListSkeleton>
            ))
          : props.banks.map(bank => (
              <SelectListOption
                key={bank.id}
                onCheck={() => props.onSelect(bank)}
                icon={<BankLogo bank={bank} />}
              >
                {bank.name}
              </SelectListOption>
            ))}
      </SelectList>
    </Container>
  );
}

export function BuyBankPageSkeleton() {
  return <BuyBankView onSelect={() => {}} />;
}

const Global = createGlobalStyle`
  body, html {
    background-color: ${props => props.theme.bgSecondary} !important;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
