import "stories-react/dist/index.css";

import styled, { css } from "styled-components";
import Stories from "stories-react";
import background1 from "icons/story-bg1.svg";
import storyListItem from "icons/story-listitem.svg";
import storyConds from "icons/story-conds.png";
import storyInfoHeader from "icons/story-info-header.png";
import storyInfoBody from "icons/story-info-body.png";
import storyInfoBg from "icons/story-info-bg.png";
import storyIntro from "icons/story-intro.png";
import { useTranslation } from "services/i18n";

const StoriesContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Container = styled.div<{ $jettons?: boolean }>`
  height: 100%;
  width: 100%;
  background:
    url(${background1}) center no-repeat,
    ${props => (props.$jettons ? `url(${storyInfoBg}),` : "")}
      linear-gradient(18deg, #a22dff 0%, #00a3ff 100%);
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;

  padding: 22.5px;
`;

const StoryContent = styled.div`
  &::before {
    border-radius: 100%;
    background: linear-gradient(270deg, #7159f7 0%, #2197ff 95.26%);
    filter: blur(199.125px);

    content: "";
    display: block;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
  }

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const StepLabel = styled.div<{ $inverse?: boolean }>`
  padding: 4px;
  border-radius: 6px;

  border: ${props => (props.$inverse ? "1px solid #fff" : undefined)};
  background: ${props => (props.$inverse ? undefined : "#fff")};
  color: ${props => (props.$inverse ? "#fff" : "#4175ff")};

  text-align: center;
  text-shadow: 0 2.295px 2.295px rgba(0, 0, 0, 0.02);
  font-size: 16px;
  line-height: 100%;
  vertical-align: bottom;
  font-weight: 600;
`;

const Header = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0 4.235px 4.235px rgba(0, 0, 0, 0.02);
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  text-transform: uppercase;
`;

const Text = styled.div`
  color: #fff;
  text-shadow: 0 2.295px 2.295px rgba(0, 0, 0, 0.02);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  ul {
    margin: 0;

    padding-left: 16px;

    display: flex;
    flex-direction: column;
    gap: 10px;

    font-size: 14px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  color: #fff;

  text-shadow: 0 2.295px 2.295px rgba(0, 0, 0, 0.02);
  font-size: 16px;
  font-weight: 500;
  line-height: 116%;
`;

const ListItem = styled.div`
  display: flex;
  gap: 12px;

  &::before {
    flex-shrink: 0;

    background: url(${storyListItem}) no-repeat center;
    background-size: contain;
    content: "";
    display: block;
    width: 16px;
    height: 16px;
  }
`;

const BonusesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const BonusesListItem = styled.div`
  color: #171b35;
  text-shadow: 0 2.295px 2.295px rgba(0, 0, 0, 0.02);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  border-radius: 10px;
  background: #fff;
  padding: 12px 10px;

  span {
    color: #fff;
    border-radius: 5px;
    padding: 4.5px 5.5px;
    background: linear-gradient(60deg, #dc40ea 1.97%, #00a3ff 123.49%);
    margin-right: 3px;
  }
`;

const ExampleList = styled.div`
  position: relative;
  z-index: 1;

  &,
  &::before,
  &::after {
    background: #fff;
    border-radius: 10px;
  }

  &::before,
  &::after {
    display: block;
    content: "";
    position: absolute;
  }

  &::before {
    z-index: -1;
    left: 30px;
    right: 30px;
    top: 0;
    bottom: -10px;

    opacity: 0.8;
  }

  &::after {
    z-index: -2;
    left: 60px;
    right: 60px;
    top: 0;
    bottom: -20px;

    opacity: 0.4;
  }
`;

const ExampleItem = styled.div`
  padding: 10px 10px;

  border-bottom: 1px solid rgba(123, 87, 253, 0.21);

  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ExampleItemHeader = styled.div`
  letter-spacing: -0.64px;
  font-size: 13px;
  font-weight: 500;
  text-shadow: 0 2.295px 2.295px rgba(0, 0, 0, 0.02);
  color: rgba(0, 0, 0, 0.37);
`;

const ExampleItemText = styled.div<{ $bonus?: boolean }>`
  color: #171b35;
  text-shadow: 0 2.295px 2.295px rgba(0, 0, 0, 0.02);
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -1px;

  ${props =>
    props.$bonus &&
    css`
      background: linear-gradient(88deg, #6b40ea 0%, #00a3ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `};
`;

const Info = styled.div`
  width: 100%;

  padding: 16px 14px;

  border-radius: 12px;
  border: 1px solid #fff;
  background: linear-gradient(180deg, #3788fe 0%, #3788fe 100%);
  backdrop-filter: blur(28.350000381469727px);

  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InfoHeader = styled.div`
  color: #fff;

  text-shadow: 0 4.235px 4.235px rgba(0, 0, 0, 0.02);
  font-size: 30px;
  font-weight: 800;
  line-height: 120%;
  text-transform: uppercase;

  display: flex;
  gap: 10px;

  &::after {
    flex-shrink: 0;

    display: block;
    content: "";
    background: url(${storyInfoHeader}) no-repeat center;
    background-size: contain;

    width: 80px;
    height: 80px;

    position: relative;
    top: -50px;
  }
`;

const InfoBody = styled(Text)`
  display: flex;
  gap: 10px;

  &::before {
    flex-shrink: 0;

    display: block;
    content: "";
    background: url(${storyInfoBody}) no-repeat center;
    background-size: contain;

    width: 80px;
    height: 80px;

    align-self: flex-end;
    position: relative;
    bottom: -50px;
  }
`;

function Story_intro() {
  const t = useTranslation();

  return (
    <Container $jettons>
      <StoryContent style={{ position: "relative", top: -15, gap: 10 }}>
        <img src={storyIntro} alt={""} style={{ width: "100%", position: "relative", top: 30 }} />
        <Header>{t("ui", "bonus_stories_intro_header")}</Header>
        <Text style={{ fontSize: 20, textAlign: "center" }}>
          {t("ui", "bonus_stories_intro_body")}
        </Text>
      </StoryContent>
    </Container>
  );
}

function Story_step1() {
  const t = useTranslation();

  return (
    <Container>
      <StoryContent>
        <StepLabel>{t("ui", "bonus_stories_step_label")} 1</StepLabel>
        <Header>{t("ui", "bonus_stories_step1_header")}</Header>
        <List>
          <ListItem>{t("ui", "bonus_stories_step1_item1")}</ListItem>
          <ListItem>{t("ui", "bonus_stories_step1_item2")}</ListItem>
        </List>
      </StoryContent>
    </Container>
  );
}

function Story_step2() {
  const t = useTranslation();

  return (
    <Container>
      <StoryContent style={{ alignItems: "stretch" }}>
        <StepLabel style={{ alignSelf: "center" }}>
          {t("ui", "bonus_stories_step_label")} 2
        </StepLabel>
        <Header>{t("ui", "bonus_stories_step2_header")}</Header>
        <Text>{t("ui", "bonus_stories_step2_body")}</Text>
        <BonusesList>
          <BonusesListItem>
            <span>{t("ui", "bonus_stories_step2_item1_value")}</span>{" "}
            {t("ui", "bonus_stories_step2_item1_label")}
          </BonusesListItem>
          <BonusesListItem>
            <span>{t("ui", "bonus_stories_step2_item2_value")}</span>{" "}
            {t("ui", "bonus_stories_step2_item2_label")}
          </BonusesListItem>
          <BonusesListItem>
            <span>{t("ui", "bonus_stories_step2_item3_value")}</span>{" "}
            {t("ui", "bonus_stories_step2_item3_label")}
          </BonusesListItem>
          <BonusesListItem>
            <span>{t("ui", "bonus_stories_step2_item4_value")}</span>{" "}
            {t("ui", "bonus_stories_step2_item4_label")}
          </BonusesListItem>
        </BonusesList>
      </StoryContent>
    </Container>
  );
}

function Story_conditions() {
  const t = useTranslation();

  return (
    <Container>
      <StoryContent>
        <img src={storyConds} alt={"📌"} width={50} height={50} />
        <Header>{t("ui", "bonus_stories_conditions_header")}</Header>
        <Text>
          <ul>
            <li>{t("ui", "bonus_stories_conditions_item1")}</li>
            <li>{t("ui", "bonus_stories_conditions_item2")}</li>
            <li>{t("ui", "bonus_stories_conditions_item3")}</li>
            <li>{t("ui", "bonus_stories_conditions_item4")}</li>
            <li>{t("ui", "bonus_stories_conditions_item5")}</li>
            <li>{t("ui", "bonus_stories_conditions_item6")}</li>
          </ul>
        </Text>
      </StoryContent>
    </Container>
  );
}

function Story_example() {
  const t = useTranslation();

  return (
    <Container>
      <StoryContent style={{ alignItems: "stretch" }}>
        <StepLabel style={{ alignSelf: "center" }} $inverse>
          {t("ui", "bonus_stories_example_label")}
        </StepLabel>
        <Header>{t("ui", "bonus_stories_example_header")}</Header>
        <Text>{t("ui", "bonus_stories_example_body1")}</Text>
        <Text>{t("ui", "bonus_stories_example_body2")}</Text>
        <ExampleList>
          <ExampleItem>
            <ExampleItemHeader>{t("ui", "bonus_stories_example_info1_label")}</ExampleItemHeader>
            <ExampleItemText>{t("ui", "bonus_stories_example_info1_value")}</ExampleItemText>
          </ExampleItem>
          <ExampleItem>
            <ExampleItemHeader>{t("ui", "bonus_stories_example_info2_label")}</ExampleItemHeader>
            <ExampleItemText $bonus>{t("ui", "bonus_stories_example_info2_value")}</ExampleItemText>
          </ExampleItem>
          <ExampleItem>
            <ExampleItemHeader>{t("ui", "bonus_stories_example_info3_label")}</ExampleItemHeader>
            <ExampleItemText $bonus>{t("ui", "bonus_stories_example_info3_value")}</ExampleItemText>
          </ExampleItem>
        </ExampleList>
      </StoryContent>
    </Container>
  );
}

function Story_step3() {
  const t = useTranslation();

  return (
    <Container>
      <StoryContent>
        <StepLabel>{t("ui", "bonus_stories_step_label")} 3</StepLabel>
        <Header>{t("ui", "bonus_stories_step3_header")}</Header>
        <Text>{t("ui", "bonus_stories_step3_body")}</Text>
      </StoryContent>
    </Container>
  );
}

function Story_step4() {
  const t = useTranslation();

  return (
    <Container>
      <StoryContent>
        <StepLabel>{t("ui", "bonus_stories_step_label")} 4</StepLabel>
        <Header>{t("ui", "bonus_stories_step4_header")}</Header>
        <Text>{t("ui", "bonus_stories_step4_body")}</Text>
      </StoryContent>
    </Container>
  );
}

function Story_info1() {
  const t = useTranslation();

  return (
    <Container $jettons>
      <Info>
        <InfoHeader>{t("ui", "bonus_stories_info1_header")}</InfoHeader>
        <InfoBody>{t("ui", "bonus_stories_info1_body")}</InfoBody>
      </Info>
    </Container>
  );
}

function Story_info2() {
  const t = useTranslation();

  return (
    <Container $jettons>
      <Info>
        <InfoHeader>{t("ui", "bonus_stories_info2_header")}</InfoHeader>
        <InfoBody>{t("ui", "bonus_stories_info2_body")}</InfoBody>
      </Info>
    </Container>
  );
}

export type BonusStoriesPageProps = {
  onComplete(): void;
};

export function BonusStoriesPage(props: BonusStoriesPageProps) {
  const type = "component";
  const duration = 9000;

  return (
    <StoriesContainer>
      <Stories
        onAllStoriesEnd={props.onComplete}
        stories={[
          { type, duration, component: Story_intro },
          { type, duration, component: Story_step1 },
          { type, duration, component: Story_step2 },
          { type, duration, component: Story_conditions },
          { type, duration, component: Story_example },
          { type, duration, component: Story_step3 },
          { type, duration, component: Story_step4 },
          { type, duration, component: Story_info1 },
          { type, duration, component: Story_info2 },
        ]}
      />
    </StoriesContainer>
  );
}
