import styled from "styled-components";
import { Currency, formatAmount } from "models";
import { transparentize } from "polished";
import { useTranslation } from "services/i18n";
import { hintSkeleton } from "styled-mixins/skeleton";

type Props = {
  value?: number;
  currency?: Currency;
  label: string;
};

function format(value: number, currency: Currency) {
  if (currency.isFiat) {
    return formatAmount(value, { ...currency, precision: 0 });
  } else {
    return formatAmount(value, currency);
  }
}

export function OrderAmount(props: Props) {
  const t = useTranslation();

  const from = props.value && props.currency ? format(props.value, props.currency) : undefined;

  return (
    <Container>
      <Label>{t("ui", props.label)}:</Label>
      <Value $small={(from?.length ?? 0) > 10}>
        {from ? from : <TextSkeleton>1000 CUR</TextSkeleton>}
      </Value>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4px;

  border-radius: 20px;
  background: ${props => transparentize(0.9, props.theme.button)};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  margin: 0 -4px;

  padding: 16px;

  background: ${props => props.theme.button};
`;

const Label = styled.div`
  color: ${props => props.theme.buttonText};
  font-size: 1rem;
  line-height: 1.3125rem;
  letter-spacing: 0.32px;
  font-weight: 400;

  flex-grow: 1;
`;

const Value = styled.div<{ $small: boolean }>`
  color: ${props => props.theme.buttonText};
  font-size: ${props => (props.$small ? 0.8 : 1)}rem;
  line-height: 1.3125rem;
  letter-spacing: 0.32px;
  font-weight: 700;

  font-family: "Helvetica Neue", "Helvetica", "system-ui";

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TextSkeleton = styled.span`
  ${hintSkeleton};
`;
