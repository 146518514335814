import { Bank } from "models";
import styled from "styled-components";
import { hintSkeleton } from "styled-mixins/skeleton";

export function BankLogo({ bank, size = 32 }: { bank?: Bank; size?: number }) {
  if (bank) {
    return (
      <Image
        $size={size}
        src={bank.icon}
        alt={bank.name}
        style={{ background: bank.formBackgroundColor }}
      />
    );
  } else {
    return <Skeleton as="span" $size={size} />;
  }
}

const Image = styled.img<{ $size: number }>`
  display: inline-block;
  border-radius: 100% !important;
  font-size: 0;
  padding: 3px;
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
`;

const Skeleton = styled(Image)`
  ${hintSkeleton};
`;
