import styled, { css, keyframes } from "styled-components";

import { useNotificationsService } from "services/notifications";
import { ReactNode, useEffect } from "react";
import { useSubscribe } from "hooks/use-subscribe";
import { useState } from "hooks/use-state";

type Props = {
  title?: ReactNode;
  message?: ReactNode;
  buttons?: Array<{ label: string; onClick(): void }>;
  onClose(): void;
};

enum AnimationState {
  Init,
  Visible,
  Hiding,
}

export function Notification(props: Props) {
  const state = useState(AnimationState.Init);

  useEffect(() => {
    const timeout = setTimeout(() => {
      state.set(AnimationState.Visible);
    });

    return () => clearTimeout(timeout);
  }, []);

  function handleClose() {
    if (state.get !== AnimationState.Hiding) {
      state.set(AnimationState.Hiding);

      setTimeout(() => {
        props.onClose();
      }, 200);
    }
  }

  return (
    <NotificationContainer
      className={state.get === AnimationState.Visible ? "visible" : undefined}
      onClick={handleClose}
    >
      {props.title && <Title>{props.title}</Title>}
      {props.message && <Message>{props.message}</Message>}
      {props.buttons && (
        <Buttons>
          {props.buttons.map(button => (
            <Button key={button.label} onClick={button.onClick}>
              {button.label}
            </Button>
          ))}
        </Buttons>
      )}
    </NotificationContainer>
  );
}

const NotificationContainer = styled.div`
  position: relative;

  padding: 16px;
  width: 100%;

  background-color: #233357;
  color: #fff;

  border-radius: 10px;

  cursor: pointer;

  transition: all 300ms ease-in-out;

  opacity: 0;
  top: -100px;

  &.visible {
    opacity: 1;
    top: 0;
  }

  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.32px;
`;

const Message = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.28px;
`;

const Buttons = styled.div`
  margin-top: 8px;

  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
`;

const Button = styled.button`
  margin: 0;

  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;

  padding: 10px 16px;
  border: 0;
  border-radius: 20px;
  background: #55a4f8;

  cursor: pointer;
`;

export type NotificationsOverlayProps = {
  /** time to leave in seconds */
  ttl: number;
};

export function NotificationsOverlay({ ttl }: NotificationsOverlayProps) {
  const notificationsService = useNotificationsService();
  const notification = useSubscribe(notificationsService, null);

  useEffect(() => {
    if (notification !== null) {
      const timeout = setTimeout(
        () => {
          notificationsService.close(notification.id);
        },
        ttl * (notification.buttons?.length === undefined ? 1000 : 3000),
      );

      return () => clearTimeout(timeout);
    }
  }, [notification, ttl]);

  if (!notification) return <></>;

  return (
    <NotificationsOverlayContainer $fullscreen={notification?.buttons?.length !== undefined}>
      {notification && (
        <Notification
          key={notification.id}
          title={notification.title}
          message={notification.message}
          buttons={notification.buttons}
          onClose={() => notificationsService.close(notification.id)}
        />
      )}
    </NotificationsOverlayContainer>
  );
}

const NotificationsOverlayContainer = styled.div<{ $fullscreen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 6px;

  ${props =>
    props.$fullscreen &&
    css`
      bottom: 0;
      transition: background 300ms linear;
      background: linear-gradient(180deg, rgb(23, 27, 53) 0%, rgba(23, 27, 53, 0) 100%);
      animation: 300ms ${keyframes`
        from { opacity: 0 }
        to   { opacity: 1 }
      `};
    `}
`;
