import React from "react";

import { Currency } from "models";
import { replicate } from "lib/replicate";
import { useTranslation } from "services/i18n";

import { FiatButton } from "./fiat-button";

import { ReactComponent as BuyFiatIcon } from "icons/buy-fiat.svg";

import * as S from "./tab-deposit.styled";

type Props = {
  currencies: Currency[] | undefined;
  fiatBanks: undefined | null | string;
  onDepositWithCard(): void;
  onDepositWithCrypto(crypto: Currency["code"]): void;
};

export function TabDeposit(props: Props) {
  const t = useTranslation();

  return (
    <S.Container>
      <FiatButton
        banks={props.fiatBanks}
        icon={<BuyFiatIcon />}
        label={t("ui", "wallet_deposit_with_card")}
        title={t("ui", "wallet_deposit_card_payment")}
        onClick={props.onDepositWithCard}
      />
      <S.ListContainer hide={props.currencies?.length === 0}>
        <S.Label>{t("ui", "wallet_deposit_with_crypto")}</S.Label>
        <S.PayWithCryptoList>
          {props.currencies === undefined
            ? replicate(10).map(i => (
                <S.PayWithCryptoItem key={i}>
                  <S.PayWithCryptoIconSkeleton />
                  <S.PayWithCryptoName>
                    <S.TextSkeleton>CODE</S.TextSkeleton>
                  </S.PayWithCryptoName>
                  <S.PayWithCryptoDesc>
                    <S.TextSkeleton>NETWORK</S.TextSkeleton>
                  </S.PayWithCryptoDesc>
                </S.PayWithCryptoItem>
              ))
            : props.currencies.map(currency => (
                <S.PayWithCryptoItem
                  key={currency.code}
                  onClick={() => props.onDepositWithCrypto(currency.code)}
                >
                  <S.PayWithCryptoIcon src={currency.icon} alt={currency.code} />
                  <S.PayWithCryptoName>{currency.displayCode ?? currency.code}</S.PayWithCryptoName>
                  <S.PayWithCryptoDesc>{currency.network}</S.PayWithCryptoDesc>
                  {currency.depositBonus &&
                  typeof currency.depositBonus === "number" &&
                  currency.depositBonus > 0 ? (
                    <S.PayWithCryptoBonus>
                      +{currency.depositBonus}% {t("ui", "bonus")}
                    </S.PayWithCryptoBonus>
                  ) : undefined}
                </S.PayWithCryptoItem>
              ))}
        </S.PayWithCryptoList>
      </S.ListContainer>
    </S.Container>
  );
}
