import type { WebApp } from ".";
import { defaultTheme } from "theme";

export class DumbWebApp implements WebApp {
  constructor() {
    alert("DEV");
  }

  themeParams = defaultTheme;
  onEvent() {}
  offEvent() {}
  ready() {}
  expand() {}

  BackButton = {
    show() {},
    hide() {},
    onClick() {},
  };

  MainButton = {
    show() {},
    hide() {},
    onClick() {},
    setText() {},
    showProgress() {},
    hideProgress() {},
    setParams() {},
  };
}

export function getWebApp(): WebApp {
  return new DumbWebApp();
}
