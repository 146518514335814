interface Message {
  name: string;
  data: unknown;
}

function isMessage(message: unknown): message is Message {
  return (
    typeof message === "object" &&
    message !== null &&
    "name" in message &&
    "data" in message &&
    typeof message.name === "string"
  );
}

type Signature<A, B> = string & { args: A; result: B };

export function signature<A, B>(name: string): Signature<A, B> {
  return name as any;
}

export async function call<A, B>(name: Signature<A, B>, data: A): Promise<B> {
  return await new Promise(resolve => {
    function handleEvent(event: MessageEvent) {
      if (isMessage(event.data) && event.data.name === name) {
        resolve(event.data.data as B);
        window.removeEventListener("message", handleEvent);
      }
    }

    window.addEventListener("message", handleEvent);
    window.parent.postMessage({ name, data } satisfies Message, "*");
  });
}

export function serve<A, B>(name: Signature<A, B>, handler: (arg: A) => Promise<B>): () => void {
  async function handleEvent(event: MessageEvent) {
    if (isMessage(event.data) && event.data.name === name) {
      const data = await handler(event.data.data as A);
      event.source?.postMessage({ name, data } satisfies Message, { targetOrigin: "*" });
    }
  }

  window.addEventListener("message", handleEvent);
  return () => window.removeEventListener("message", handleEvent);
}
