import { useState as useStateOriginal } from "react";
import type { Dispatch, SetStateAction } from "react";

export type State<T> = {
  readonly get: T;
  set: Dispatch<SetStateAction<T>>;
};

type InitialValue<T> = T | (() => T);

export function useState<T>(value: InitialValue<T>): State<T> {
  const [get, set] = useStateOriginal(value);
  return { get, set };
}
