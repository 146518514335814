// @ts-ignore
import replaceAll from "string.prototype.replaceall";

export function render(template: string, values: Record<string, unknown>): string {
  function substitute(template: string, [key, value]: [string, unknown]) {
    return replaceAll(template, `{${key}}`, `${value}`);
  }

  return Object.entries(values).reduce(substitute, template ?? "");
}
