import styled, { css } from "styled-components";

import { Currency } from "models";
import { transparentize } from "polished";
import { replicate } from "lib/replicate";
import { hintSkeleton } from "styled-mixins/skeleton";
import { useTranslation } from "services/i18n";

import { FiatButton } from "./fiat-button";

import { ReactComponent as BuyFiatIcon } from "icons/buy-fiat.svg";

type Props = {
  currencies: Currency[] | undefined;
  fiatBanks: undefined | null | string;
  onWithdrawWithCard(): void;
  onWithdrawWithCrypto(crypto: Currency["code"]): void;
};

export function TabWithdraw(props: Props) {
  const t = useTranslation();

  return (
    <Container>
      <FiatButton
        banks={props.fiatBanks}
        icon={<BuyFiatIcon />}
        label={t("ui", "wallet_withdraw_to_card")}
        title={t("ui", "wallet_withdraw_card_payment")}
        onClick={props.onWithdrawWithCard}
      />
      {(props.currencies === undefined || props.currencies.length > 0) && (
        <ListContainer>
          <Label>{t("ui", "wallet_withdraw_to_crypto")}</Label>
          <PayWithCryptoList>
            {props.currencies === undefined
              ? replicate(3).map(i => (
                  <PayWithCryptoItem key={i}>
                    <PayWithCryptoIconSkeleton />
                    <PayWithCryptoName>
                      <TextSkeleton>CODE</TextSkeleton>
                    </PayWithCryptoName>
                    <PayWithCryptoDesc>
                      <TextSkeleton>NETWORK</TextSkeleton>
                    </PayWithCryptoDesc>
                  </PayWithCryptoItem>
                ))
              : props.currencies.map(currency => (
                  <PayWithCryptoItem
                    key={currency.code}
                    onClick={() => props.onWithdrawWithCrypto(currency.code)}
                  >
                    <PayWithCryptoIcon src={currency.icon} alt={currency.code} />
                    <PayWithCryptoName>{currency.displayCode ?? currency.code}</PayWithCryptoName>
                    <PayWithCryptoDesc>{currency.network}</PayWithCryptoDesc>
                  </PayWithCryptoItem>
                ))}
          </PayWithCryptoList>
        </ListContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.div`
  padding-left: 6px;

  color: ${props => props.theme.hint};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.24px;
  text-transform: uppercase;
`;

const PayWithCryptoList = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 0;

  margin-left: -4px;
  margin-right: -4px;
  border-radius: 20px;
  background: ${props => transparentize(0.9, props.theme.button)};

  overflow: hidden;
`;

const PayWithCryptoItem = styled.button`
  display: grid;
  grid-template:
    "icon name"
    "icon desc"
    / min-content 1fr;
  column-gap: 10px;

  align-items: center;

  border: 0;
  padding: 8px 16px;
  margin: 0;
  background: none;
  text-align: left;

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;

      &:hover {
        background: ${props => transparentize(0.9, props.theme.button)};
      }
    `}
  &:not(:last-child) {
    border-bottom: 1px solid var(--dark-button-color-opacity-10, rgba(85, 164, 248, 0.1));
  }
`;

const PayWithCryptoIcon = styled.img`
  grid-area: icon;
  width: 40px;
  height: 40px;
`;

const PayWithCryptoIconSkeleton = styled.div`
  grid-area: icon;
  width: 40px;
  height: 40px;

  ${hintSkeleton};

  border-radius: 100%;
`;

const PayWithCryptoName = styled.div`
  grid-area: name;

  color: ${props => props.theme.text};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3125rem;
  letter-spacing: 0.32px;
`;

const PayWithCryptoDesc = styled.div`
  grid-area: desc;

  color: ${props => props.theme.hint};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.28px;
`;

const TextSkeleton = styled.span`
  ${hintSkeleton};
`;
