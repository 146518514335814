import { transparentize } from "polished";
import { css, keyframes } from "styled-components";

const animation = keyframes`
  0% {
    background-position: 100% 100%;
  }
  50%, 100% {
    background-position: 0% 0%;
  }
`;

export const hintSkeleton = css`
  overflow: hidden;
  ${props => skeleton({ bg: transparentize(0.9, props.theme.hint), fg: props.theme.hint })}
`;

export function skeleton({ bg, fg }: { bg: string; fg: string }) {
  return css`
    &,
    * {
      color: transparent !important;
    }

    user-select: none;

    background-color: ${bg};

    position: relative;

    border-radius: 0.3rem;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      inset: 0;

      border-radius: 0.3rem;
      overflow: hidden;

      background: linear-gradient(120deg, ${bg} 0%, ${bg} 40%, ${fg} 50%, ${bg} 60%, ${bg} 100%);

      background-size: 300% 300%;
      animation: 1s infinite ease ${animation};
    }
  `;
}
