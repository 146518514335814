import WebSocket from "reconnecting-websocket";
import { createContext, useContext } from "react";
import { isLeft } from "fp-ts/Either";

import { EventBus, Atom } from "lib/event-bus";
import { IApiService } from "./api";
import { useSubscribe } from "hooks/use-subscribe";

import { Transaction } from "models";

export class UnknownTransactionsMessage extends Error {
  name = "UnknownTransactionsMessage";
}

export interface ITransactionsService extends TransactionsService {}

function addTransaction(transaction: Transaction, transactions: Transaction[]): Transaction[] {
  const newTransactions: Transaction[] = [];
  let isNew = true;

  for (const t of transactions) {
    if (t.id === transaction.id) {
      newTransactions.push(transaction);
      isNew = false;
    } else {
      newTransactions.push(t);
    }
  }

  if (isNew) {
    newTransactions.unshift(transaction);
  }

  return newTransactions;
}

export class TransactionsService extends EventBus<Transaction> {
  private connection?: WebSocket;
  public initial?: Promise<Transaction[]>;

  public transactions$ = new Atom<Transaction[] | null>(null);

  constructor(
    private wsUrl: string,
    private token: string,
  ) {
    super();
  }

  public connect(api: IApiService) {
    const connection = new WebSocket(this.wsUrl + "/wallet/txns");

    this.initial = api.transactions();
    this.initial.then(transactions => this.transactions$.set(transactions));

    connection.addEventListener("open", () => {
      connection.send(this.token);
    });

    connection.addEventListener("message", event => {
      const message = Transaction.decode(JSON.parse(event.data));
      if (isLeft(message)) throw new UnknownTransactionsMessage(event.data);

      const transaction = message.right;
      this.dispatch(transaction);
      this.transactions$.update(prevTransactions => {
        if (prevTransactions === null) {
          return [transaction];
        } else {
          return addTransaction(transaction, prevTransactions);
        }
      });
    });

    this.connection = connection;
  }

  public disconnect() {
    this.connection?.close();
  }
}

export const TransactionsServiceContext = createContext<null | ITransactionsService>(null);

export function useTransactionsService(): ITransactionsService {
  const service = useContext(TransactionsServiceContext);
  if (service === null) throw new Error("Wrap with TransactionsServiceContext.Provider");
  return service;
}

export function useTransactions(): null | Transaction[] {
  const service = useTransactionsService();
  const txns = useSubscribe(service.transactions$, null);
  return txns;
}
