import styled, { createGlobalStyle } from "styled-components";
import { transparentize } from "polished";

import { ReactComponent as BuyArrowIcon } from "icons/buy-new-user-arrow.svg";

const GlobalStyles = createGlobalStyle`
    body, html {
        background-color: ${props => props.theme.bgSecondary} !important;
        color: ${props => props.theme.text} !important;
    }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - 32px);
`;

const AppContainer = styled.div`
  margin: -16px -20px;
  padding: 16px 20px;

  background-color: ${props => props.theme.bg};
  border-bottom: 1px solid ${props => transparentize(0.9, props.theme.link)};

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TabsContainer = styled.div`
  margin: 0 -20px 16px;
`;

const NewUserContainer = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  margin-left: -4px;
  margin-right: -4px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.div`
  color: ${props => props.theme.hint};
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.26px;
  text-transform: uppercase;

  padding-left: 4px;
`;

const NewUserButton = styled.button`
  margin: 0;
  border: 0;
  cursor: pointer;
  text-align: left;

  display: flex;
  padding: 16px 8px 16px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 14px;
  background: ${props => props.theme.bg};
`;

const NewUserButtonLabel = styled.div`
  color: ${props => props.theme.text};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;

  flex-grow: 1;
`;

const NewUserButtonArrow = styled(BuyArrowIcon)`
  color: ${props => props.theme.hint};
`;

export {
  GlobalStyles,
  Container,
  AppContainer,
  NewUserButton,
  NewUserButtonLabel,
  TabsContainer,
  NewUserContainer,
  Label,
  NewUserButtonArrow,
};
