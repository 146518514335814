import styled, { keyframes } from "styled-components";
import logoIcon from "icons/jetton.svg";

export function Loader() {
  return (
    <>
      <Spacer />
      <Container>
        <img src={logoIcon} alt="JetTon" />
      </Container>
    </>
  );
}

const Spacer = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${props => props.theme.bg};

  display: flex;
  justify-content: center;
  align-items: center;

  svg,
  img {
    width: 100px;

    animation: //
      200ms ease-in-out 1 ${keyframes`
        from { opacity: 0; transform: scale(1.1); }
        to   { opacity: 1; transform: scale(1.2); }
      `},
      1s ease-in-out infinite 200ms ${keyframes`
        from { transform: scale(1.2); }
        50%  { transform: scale(1); }
        to   { transform: scale(1.2); }
      `};
  }
`;
