import type { TelegramTheme, WebApp } from ".";
import { defaultTheme } from "theme";

type IncomeMessage =
  | { type: "BackButton.click" }
  | { type: "MainButton.click" }
  | { type: "theme"; theme: TelegramTheme };

type OutcomeMessage =
  | { type: "BackButton.show" }
  | { type: "BackButton.hide" }
  | { type: "BackButton.setText"; text: string }
  | { type: "BackButton.setDisabled"; disabled: boolean }
  | { type: "MainButton.show" }
  | { type: "MainButton.hide" }
  | { type: "MainButton.setText"; text: string }
  | { type: "MainButton.showProgress" }
  | { type: "MainButton.hideProgress" }
  | { type: "MainButton.setParams"; params: { color: string } }
  | { type: "ready" };

export class IFrameWebApp implements WebApp {
  themeParams = defaultTheme;
  private themeParamsHandlers = new Set<() => void>();

  onEvent(name: "themeChanged", handler: () => void) {
    this.themeParamsHandlers.add(handler);
  }

  offEvent() {}

  ready() {
    this.dispatchOutcomeMessage({ type: "ready" });
  }

  expand() {}

  BackButton = {
    show: () => this.dispatchOutcomeMessage({ type: "BackButton.show" }),
    hide: () => this.dispatchOutcomeMessage({ type: "BackButton.hide" }),
    setText: (text: string) => this.dispatchOutcomeMessage({ type: "BackButton.setText", text }),
    setDisabled: (disabled: boolean) =>
      this.dispatchOutcomeMessage({ type: "BackButton.setDisabled", disabled }),

    onClick: (handler: () => void) => this.BackButton.handlers.add(handler),
    handlers: new Set<() => void>(),
  };

  MainButton = {
    show: () => this.dispatchOutcomeMessage({ type: "MainButton.show" }),
    hide: () => this.dispatchOutcomeMessage({ type: "MainButton.hide" }),
    setText: (text: string) => this.dispatchOutcomeMessage({ type: "MainButton.setText", text }),
    showProgress: () => this.dispatchOutcomeMessage({ type: "MainButton.showProgress" }),
    hideProgress: () => this.dispatchOutcomeMessage({ type: "MainButton.hideProgress" }),
    setParams: (params: { color: string }) =>
      this.dispatchOutcomeMessage({ type: "MainButton.setParams", params }),

    onClick: (handler: () => void) => this.MainButton.handlers.add(handler),
    handlers: new Set<() => void>(),
  };

  connect() {
    window.addEventListener("message", event => {
      try {
        this.handleIncomeMessage(JSON.parse(event.data));
      } catch (e) {}
    });
  }

  private handleIncomeMessage(message: IncomeMessage) {
    switch (message.type) {
      case "BackButton.click":
        return this.BackButton.handlers.forEach(handler => handler());
      case "MainButton.click":
        return this.MainButton.handlers.forEach(handler => handler());
      case "theme":
        this.themeParams = message.theme;
        return this.themeParamsHandlers.forEach(handler => handler());
      default:
        message satisfies never;
    }
  }

  private dispatchOutcomeMessage(message: OutcomeMessage) {
    window.parent.postMessage(JSON.stringify(message), "*");
  }
}

export function getWebApp(): WebApp | null {
  const webapp = new IFrameWebApp();
  webapp.connect();
  return webapp;
}
