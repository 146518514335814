import { TelegramTheme } from "services/webapp";

export type Theme = {
  bg: string;
  bgSecondary: string;
  text: string;
  hint: string;
  button: string;
  buttonText: string;
  link: string;

  // custom
  error: string;
  success: string;
};

export const defaultTheme: TelegramTheme = {
  bg_color: "#1A222C",
  button_color: "#55a4f8",
  button_text_color: "#ffffff",
  hint_color: "#B4C2D3",
  link_color: "#79BAF4",
  secondary_bg_color: "#1c1c1c",
  text_color: "#ffffff",
};

export function fromTelegramTheme(telegramTheme: TelegramTheme): Theme {
  return {
    bg: telegramTheme.bg_color,
    text: telegramTheme.text_color,
    hint: telegramTheme.hint_color,
    link: telegramTheme.link_color,
    button: telegramTheme.button_color,
    buttonText: telegramTheme.button_text_color,
    bgSecondary: telegramTheme.secondary_bg_color,

    error: "#EB553E",
    success: "#68CE67",
  };
}
