import { css } from "styled-components";

export const customScrollbars = css`
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(23, 27, 53, 0.4);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(50, 60, 119, 1);
    border: 2px solid transparent;
    border-radius: 7px;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(60, 71, 135, 1);
    border: 2px solid transparent;
    border-radius: 7px;
    background-clip: content-box;
  }
`;
