import { createContext, useContext } from "react";
import { Atom } from "lib/event-bus";

export type NotificationMessage = {
  id: number;
  title?: string;
  message?: string;
  buttons?: Array<{ label: string; onClick(): void }>;
};

export class NotificationsService extends Atom<NotificationMessage | null> {
  private uuid: number = 0;

  constructor() {
    super(null);
  }

  public notify(message: Omit<NotificationMessage, "id">): void {
    super.set({ ...message, id: ++this.uuid });
  }

  public close(id: NotificationMessage["id"]) {
    if (this.value?.id === id) {
      this.set(null);
    }
  }
}

export const NotificationsServiceContext = createContext(new NotificationsService());

export function useNotificationsService(): NotificationsService {
  return useContext(NotificationsServiceContext);
}
