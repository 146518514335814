import { createContext, useContext } from "react";

type The<A, B extends A> = B;

type TrackedEvent = The<
  [string] | [string, Properties],
  // Main
  | ["[Wallet] open"]
  | ["[Wallet] back to main page"]
  | ["[Wallet] fiat changed", { fiat: string }]
  | ["[Wallet] crypto changed", { crypto: string }]

  // Buy
  | ["[Wallet] Buy: open"]
  | ["[Wallet] Buy: currency selected", { currency: string }]
  | ["[Wallet] Buy: back to currency"]
  | ["[Wallet] Buy: amount provided", { currency: string; fiat: number; jetton: number | null }]
  | ["[Wallet] Buy: back to amount"]
  | ["[Wallet] Buy: bank selected", { bank: string }]
  | ["[Wallet] Buy: back to bank"]
  | ["[Wallet] Buy: back to payment"]
  | ["[Wallet] Buy: confirmed"]
  | ["[Wallet] Buy: canceled"]
  | ["[Wallet] Buy: timeouted"]
  | ["[Wallet] Buy: support clicked"]
  | ["[Wallet] Buy: disput open"]
  | ["[Wallet] Buy: marked as paid"]
  | ["[Wallet] Buy: back to status"]

  // Sell
  | ["[Wallet] Sell: open"]
  | ["[Wallet] Sell: amount provided", { currency: string; fiat: number; jetton: number }]
  | ["[Wallet] Sell: back to amount"]
  | ["[Wallet] Sell: bank selected", { bank: string }]
  | ["[Wallet] Sell: back to bank"]
  | ["[Wallet] Sell: details provided", Record<"phone" | "card" | "name", null | string>]
  // TODO: временно, пока не разбирался как тут типизируется
  | ["[Wallet] Sell: external details provided", any]
  | ["[Wallet] Sell: back to details"]
  | ["[Wallet] Sell: confirmation confirmed"]
  | ["[Wallet] Sell: confirmation canceled"]

  // Deposit
  | ["[Wallet] Deposit: open"]
  | ["[Wallet] Deposit: currency selected", { currency: string }]
  | [
      "[Wallet] Deposit: ton connect amount provided",
      { currency: string; amount: number; address: string },
    ]
  | [
      "[Wallet] Deposit: ton connect completed",
      { currency: string; amount: number; address: string },
    ]

  // Withdraw
  | ["[Wallet] Withdraw: open"]
  | ["[Wallet] Withdraw: currency selected", { currency: string }]
  | ["[Wallet] Withdraw: address provided", { currency: string; address: string }]
  | ["[Wallet] Withdraw: back to address", { currency: string }]
  | ["[Wallet] Withdraw: amount provided", { currency: string; amount: number }]
  | ["[Wallet] Withdraw: back to amount", { currency: string; address: string }]
  | ["[Wallet] Withdraw: confirmed", { currency: string; amount: string; address: string }]
>;

type Properties = {
  [key: string]: number | string | null | Properties;
};

declare global {
  interface Window {
    amplitude?: {
      init?(token: string, options: unknown): void;
      track?(args: { user_id?: string; event_type: string; event_properties?: Properties }): void;
    };
  }
}

export class TrackService {
  private userId?: string;

  async mount(token: string) {
    if (process.env.REACT_APP_AMPLITUDE_TOKEN) {
      window.amplitude?.init?.(process.env.REACT_APP_AMPLITUDE_TOKEN, {
        defaultTracking: {
          pageViews: false,
          formInteractions: false,
        },
      });
    }

    const user = await fetch(process.env.REACT_APP_API + "/me", {
      headers: new Headers({ Authorization: token }),
    }).then(_ => _.json());
    this.userId = user?.globalId;
  }

  async track(...[event_type, event_properties]: TrackedEvent) {
    if (process.env.REACT_APP_AMPLITUDE_TOKEN) {
      return window.amplitude?.track?.({ user_id: this.userId, event_type, event_properties });
    }
  }
}

export const TrackServiceContext = createContext<null | TrackService>(null);

export function useTrack(): (...args: TrackedEvent) => void {
  const service = useContext(TrackServiceContext);
  if (service === null) throw new Error("Wrap with TrackServiceContext.Provider");
  return (...args) => service.track(...args);
}
