import * as telegram from "./telegram";
import * as iframe from "./iframe";
import * as dumb from "./dumb";

export type TelegramTheme = {
  bg_color: string;
  text_color: string;
  hint_color: string;
  link_color: string;
  button_color: string;
  button_text_color: string;
  secondary_bg_color: string;
};

// prettier-ignore
type TelegramEvents =
  | [type: "themeChanged", handler: () => void];

export interface WebApp {
  initData?: string;
  themeParams: TelegramTheme;
  onEvent(...args: TelegramEvents): void;
  offEvent(...args: TelegramEvents): void;
  ready(): void;
  expand(): void;
  BackButton: {
    show(): void;
    hide(): void;
    setText?(text: string): void;
    setDisabled?(disabled: boolean): void;
    onClick(cb: () => void): void;
  };
  MainButton: {
    show(): void;
    hide(): void;
    onClick(cb: () => void): void;
    setText(text: string): void;
    showProgress(): void;
    hideProgress(): void;
    setParams(params: { color: string }): void;
  };
}

declare global {
  interface Window {
    Telegram?: {
      WebApp: WebApp;
    };
  }
}

export function getWebApp(): WebApp {
  return telegram.getWebApp() ?? iframe.getWebApp() ?? dumb.getWebApp();
}
