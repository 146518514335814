import { useContext, createContext } from "react";

import { Currency, Transaction, Bank, BuyPaymentDetails, SellPaymentDetails } from "models";
import { SellOrder } from "shared/models/sell";
import { BuyOrder } from "shared/models/buy";
import { ExternalDetailsDto } from "shared/models/external-details";
import { NotificationsService } from "./notifications";
import { I18nService } from "./i18n";

export type Vocabulary = {
  language: string;
  ui: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
};

export type Address = {
  address: string;
};

export type DepositTransaction = {
  address: string;
  minAmount: string;
};

export type WithdrawTransaction = {
  id: string;
  minAmount: string;
};

export type DepositInvoice = {
  href: string;
};

export class ApiError extends Error {
  public name = "API Error";
  public request?: Request;
  public response?: Response;
  constructor({ request, response }: { request?: Request; response?: Response } = {}) {
    super();
    this.request = request;
    this.response = response;
  }
}

export class UnauthorizedError extends Error {
  name = "Unauthorized";

  constructor(public reason: "invalid_token" | "banned" | "adult") {
    super(reason);
    this.normalize();
  }

  private normalize() {
    if (!["invalid_token", "banned", "adult"].includes(this.reason)) {
      this.reason = "invalid_token";
    }
  }
}

export class InternalServerError extends ApiError {
  name = "Internal Server Error";
}

declare global {
  interface GlobalEventHandlersEventMap {
    "api-error": ApiErrorEvent;
  }
}

export class ApiErrorEvent extends Event {
  constructor(public error: ApiError) {
    super("api-error");
  }
}

export interface IApiService extends ApiService {}

export type BuyBankOrderDetails = {
  senderBank: Bank;
  recipientBank: Bank;
  paymentDetails: BuyPaymentDetails;
  expiredAt: number;
};

export type FeeLevels = Array<{
  from: number;
  fee: number;
}>;

export class ApiService {
  constructor(
    private apiUrl: string,
    private token: string,
    private i18n: I18nService,
    private notifications?: NotificationsService,
  ) {}

  async validateResponse(request: Request, response: Response): Promise<void> {
    if (response.status === 401) {
      const { detail } = await response.json();
      window.dispatchEvent(new ApiErrorEvent(new UnauthorizedError(detail)));
    }

    if (response.status === 417) {
      const { detail, payload } = await response.json();
      this.notifications?.notify({
        message: this.i18n.translateWithFallback("ui", detail, payload ?? undefined),
      });
    }

    if (response.status === 423) {
      const { ip, country: countryName, countryImage: countryFlag } = await response.json();
      window.parent.postMessage({ status: 423, ip, countryFlag, countryName }, "*");
    }

    if (response.status === 500) {
      window.dispatchEvent(new ApiErrorEvent(new InternalServerError({ request, response })));
    }

    if (!response?.ok) {
      throw new ApiError({ request, response });
    }
  }

  private request(url: string, method: "GET", init: RequestInit): Request;
  private request(
    url: string,
    method: "POST" | "PATCH" | "PUT",
    init: RequestInit,
    data: unknown,
  ): Request;
  private request(url: string, method: string, init: RequestInit, data?: unknown): Request {
    const headers = new Headers({
      Authorization: this.token,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": "true",
    });

    const body = data ? JSON.stringify(data) : undefined;

    return new Request(this.apiUrl + url, { ...init, headers, method, body });
  }

  private async get<T>(url: string, init?: RequestInit): Promise<T> {
    const request = this.request(url, "GET", init ?? {});
    const response = await fetch(request);
    await this.validateResponse(request, response);
    return await response.json();
  }

  private async post<T>(url: string, data: unknown, init?: RequestInit): Promise<T> {
    const request = this.request(url, "POST", init ?? {}, data);
    const response = await fetch(request);
    await this.validateResponse(request, response);
    return await response.json();
  }

  private async patch<T>(url: string, data: unknown, init?: RequestInit): Promise<T> {
    const request = this.request(url, "PATCH", init ?? {}, data);
    const response = await fetch(request);
    await this.validateResponse(request, response);
    return await response.json();
  }

  async getI18n(locale: string): Promise<Vocabulary> {
    return await this.get("/i18n?" + new URLSearchParams({ locale }));
  }

  async walletAddress(currency: string): Promise<DepositTransaction> {
    return await this.post(`/wallet/txns/deposit`, { currency });
  }

  async recipients(currency: Currency["code"]): Promise<string[]> {
    return await this.get(`/wallet/addresses/${currency}`);
  }

  async transactions(): Promise<Transaction[]> {
    return await this.get("/wallet/txns");
  }

  async newDepositInvoice(provider: String, amount: number): Promise<DepositInvoice> {
    return await this.post(`/wallet/deposit/${provider}`, { amount });
  }

  async getCurrencies(): Promise<Currency[]> {
    return await this.get(`/wallet/currencies`);
  }

  async getWithdrawFee(
    currency: string,
    amount: string,
  ): Promise<{ fee: number; maxAmount: number }> {
    const query = new URLSearchParams({ amount });
    return await this.get(`/wallet/currencies/${currency}/withdraw_fee?${query}`);
  }

  async withdrawTransaction(
    currency: Currency["code"],
    address: string,
    amount: string,
    memo?: string,
  ) {
    await this.post("/wallet/txns/withdraw", {
      currency,
      address,
      amount,
      ...(memo ? { memo } : {}),
    });
  }

  async setActiveCurrency(currency: Partial<Record<"active" | "primary", Currency["code"]>>) {
    await this.patch("/me", { currency });
  }

  async getOrderCurrencies(): Promise<(Currency & { topBanks: string; valuePresets: number[] })[]> {
    return await this.get("/wallet/orders/currencies");
  }

  async getBuyOrder(id: string): Promise<BuyOrder> {
    return await this.get(`/wallet/orders/buy/${id}`);
  }

  async createBuyOrder(): Promise<BuyOrder & { status: "enter_amount" }> {
    return await this.post("/wallet/orders/buy", undefined);
  }

  async setBuyOrderCurrency(id: string, currency: string): Promise<void> {
    return await this.post(`/wallet/orders/buy/${id}/currency`, { currency });
  }

  async setBuyOrderAmount(id: string, valueFrom: number, callbackUrl?: string): Promise<BuyOrder> {
    return await this.post(`/wallet/orders/buy/${id}/amount`, { valueFrom, callbackUrl });
  }

  async setBuyOrderBank(id: string, bank: Bank): Promise<BuyOrder> {
    return await this.post(`/wallet/orders/buy/${id}/bank`, bank);
  }

  async confirmBuyOrder(
    id: string,
    details: { name: string; fileName?: string; fileContent?: string },
  ): Promise<void> {
    return await this.post(`/wallet/orders/buy/${id}/paid`, details);
  }

  async openDisputeForBuyOrder(
    id: BuyOrder["id"],
    details: {
      problem: string;
      acceptOption: string;
      image: {
        name: string;
        fileContent: string;
        fileName: string;
      };
      comment?: string;
      amount?: string;
    },
  ) {
    return await this.post(`/wallet/orders/buy/${id}/dispute`, details);
  }

  async getBuyOrderBanks(id: string): Promise<Bank[]> {
    return await this.get(`/wallet/orders/buy/${id}/banks`);
  }

  async setBuyOrderExternalDetails(
    id: string,
    details: ExternalDetailsDto & { callbackUrl: string },
  ): Promise<BuyOrder> {
    return await this.post(`/wallet/orders/buy/${id}/external_details`, details);
  }

  async getSellOrder(id: string): Promise<SellOrder> {
    return await this.get(`/wallet/orders/sell/${id}`);
  }

  async createSellOrder(): Promise<SellOrder & { status: "created" }> {
    return await this.post("/wallet/orders/sell", undefined);
  }

  async setSellOrderAmount(
    id: string,
    values: Record<"valueFrom" | "valueTo", number>,
  ): Promise<void> {
    return await this.post(`/wallet/orders/sell/${id}/amount`, values);
  }

  async setSellOrderBank(id: string, bank: Bank): Promise<void> {
    return await this.post(`/wallet/orders/sell/${id}/bank`, bank);
  }

  async setSellOrderDetails(id: string, details: SellPaymentDetails): Promise<void> {
    return await this.post(`/wallet/orders/sell/${id}/details`, details);
  }

  // TODO: Пока оставлю один и тот же вызов, но поменяю DTO
  async setSellOrderExternalDetails(id: string, details: ExternalDetailsDto): Promise<void> {
    return await this.post(`/wallet/orders/sell/${id}/details`, details);
  }

  async confirmSellOrder(id: string): Promise<void> {
    return await this.post(`/wallet/orders/sell/${id}/confirm`, undefined);
  }

  async getSellOrderBanks(id: string): Promise<Bank[]> {
    return await this.get(`/wallet/orders/sell/${id}/banks`);
  }

  async openWebAppDispute(id: BuyOrder["id"]) {
    return await this.get(`/wallet/orders/buy/${id}/dispute/send`);
  }

  async cancelTransaction(id: Transaction["id"]) {
    await this.post(`/wallet/txns/cancel/${id}`, {});
  }

  async cancelBuyOrder(id: BuyOrder["id"]) {
    await this.post(`/wallet/orders/buy/${id}/cancel`, {});
  }

  async cancelSellOrder(id: SellOrder["id"]) {
    await this.post(`/wallet/orders/sell/${id}/cancel`, {});
  }
}

export const ApiServiceContext = createContext<null | IApiService>(null);

export function useApiService(): IApiService {
  const service = useContext(ApiServiceContext);
  if (service === null) throw new Error("Wrap with ApiServiceContext.Provider");
  return service;
}

export function isCustomException(e: unknown): e is ApiError {
  return e instanceof ApiError && e.response?.status === 417;
}
