import { useEffect } from "react";

import { BuyOrder } from "shared/models/buy";
import { useApiService } from "services/api";
import { usePromiseLatest } from "./use-promise";
import { useNavigate } from "./use-navigate";
import { reroute } from "pages/buy/reroute";

export function useOrder<statuses extends readonly BuyOrder["status"][]>(
  id: string,
  statuses: statuses,
  deps: unknown[],
): undefined | (BuyOrder & { status: statuses[number] }) {
  const api = useApiService();
  const order = usePromiseLatest(() => api.getBuyOrder(id), deps);
  const navigate = useNavigate();

  useEffect(() => {
    if (order && !statuses.includes(order.status)) {
      navigate(reroute(order));
    }
  }, [order?.status]);

  if (order && statuses.includes(order.status)) {
    return order;
  }
}
