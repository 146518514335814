import { transparentize } from "polished";
import styled from "styled-components";

export const ListHeader = styled.div`
  color: ${props => props.theme.hint};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.24px;
  text-transform: uppercase;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 16px;
  background: ${props => transparentize(0.9, props.theme.button)};
  border-radius: 20px;
  background: rgba(85, 164, 248, 0.1);
`;

type Parts = Partial<Record<"icon" | "title" | "description" | "right" | "under", boolean>>;

function makeGridTemplate(parts: Parts): string {
  const rows: string[] = [];

  if (parts.title) {
    const row: string[] = [];
    if (parts.icon) row.push("icon");
    row.push("title");
    if (parts.right) row.push("right");

    rows.push(row.join(" "));
  }

  if (parts.description) {
    const row: string[] = [];
    if (parts.icon) row.push("icon");
    row.push("description");
    if (parts.right) row.push("right");

    rows.push(row.join(" "));
  }

  if (parts.under) {
    const row: string[] = [];
    if (parts.icon) row.push("under");
    row.push("under");
    if (parts.right) row.push("under");

    rows.push(row.join(" "));
  }

  const template = rows.map(row => JSON.stringify(row)).join("\n");
  const sizes = `
    ${parts.icon ? "min-content" : ""}
    ${parts.title || parts.description ? "1fr" : ""}
    ${parts.right ? "min-content" : ""}
  `;

  return `${template}\n / ${sizes}`;
}

const ListItemBorder = styled.div`
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 0;
  height: 1px;
  background-color: ${props => transparentize(0.9, props.theme.link)};
`;

const ListItem = styled.div<{ $parts: Parts; $selected?: boolean }>`
  background: none;
  border: 0;
  text-decoration: none;

  display: grid;
  grid-template: ${props => makeGridTemplate(props.$parts)};
  column-gap: 10px;
  row-gap: 2px;

  &:last-child ${ListItemBorder} {
    display: none;
  }

  transition-duration: 100ms;

  padding: 10px;
  margin: -1px -10px 0;

  cursor: ${props => (props.onClick ? "pointer" : "normal")};

  background-color: ${props =>
    props.$selected ? `${transparentize(0.9, props.theme.button)} !important` : "none"};

  border-radius: 20px;

  position: relative;

  &:hover {
    background-color: ${props => props.onClick && transparentize(0.95, props.theme.button)};
  }
`;

const ListItemIconContainer = styled.div`
  grid-area: icon;
  font-size: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
`;

const ListItemIcon = styled.div`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${props => props.theme.text};

  @media (max-width: 300px) {
    display: none;
  }
`;

const ListItemBadge = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;

  width: 18px;
  height: 18px;

  box-shadow: 0 0 2px 0 rgba(34, 41, 84, 0.6);
  border-radius: 50%;
`;

const ListItemLine = styled.div`
  display: flex;
  align-items: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ListItemTitle = styled(ListItemLine)`
  grid-area: title;

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;

  color: ${props => props.theme.text};
`;

const ListItemDescription = styled(ListItemLine)`
  grid-area: description;

  white-space: wrap;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${props => props.theme.hint};
`;

const ListItemRight = styled.div`
  grid-area: right;

  display: flex;
  align-items: center;
  justify-content: center;

  white-space: nowrap;

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${props => props.theme.text};
`;

const ListItemUnder = styled.div`
  grid-area: under;
  margin-top: 8px;
`;

export function ListItemLayout(props: {
  icon?: React.ReactNode;
  badge?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  right?: React.ReactNode;
  under?: React.ReactNode;
  selected?: boolean;
  onClick?(): void;
  href?: string;
}) {
  return (
    <ListItem
      as={props.href ? "a" : "div"}
      href={props.href}
      target={props.href ? "_blank" : undefined}
      onClick={props.onClick}
      $parts={{
        icon: props.icon !== undefined,
        title: props.title !== undefined,
        description: props.description !== undefined,
        right: props.right !== undefined,
        under: props.under !== undefined,
      }}
      $selected={props.selected}
    >
      {props.icon && (
        <ListItemIconContainer>
          <ListItemIcon>{props.icon}</ListItemIcon>
          {props.badge && <ListItemBadge src={props.badge} alt="" />}
        </ListItemIconContainer>
      )}
      {props.title && <ListItemTitle>{props.title}</ListItemTitle>}
      {props.description && <ListItemDescription>{props.description}</ListItemDescription>}
      {props.right && <ListItemRight>{props.right}</ListItemRight>}
      {props.under && <ListItemUnder>{props.under}</ListItemUnder>}
      <ListItemBorder />
    </ListItem>
  );
}
