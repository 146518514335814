export type Subscriber<Msg> = (msg: Msg) => void;
export type Unsubscriber = () => void;

export class EventBus<Msg> {
  private subscriptions: Set<Subscriber<Msg>> = new Set();

  public subscribe(subscriber: Subscriber<Msg>): Unsubscriber {
    this.subscriptions.add(subscriber);
    return () => this.subscriptions.delete(subscriber);
  }

  protected dispatch(msg: Msg) {
    this.subscriptions.forEach(subscription => subscription(msg));
  }
}

export class Atom<T> extends EventBus<T> {
  constructor(public value: T) {
    super();
  }

  public subscribe(subscriber: Subscriber<T>): Unsubscriber {
    subscriber(this.value);
    return super.subscribe(subscriber);
  }

  public set(value: T) {
    if (value !== this.value) {
      this.value = value;
      super.dispatch(value);
    }
  }

  public update(f: (value: T) => T) {
    this.set(f(this.value));
  }
}
