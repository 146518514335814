import { createContext, useContext, useEffect, useRef } from "react";
import { useSubscribe } from "hooks/use-subscribe";
import { Atom } from "lib/event-bus";
import { debounce } from "lib/debounce";

import type { TelegramTheme, WebApp } from "./webapp";
import { defaultTheme } from "../theme";

type BackButtonState = {
  text: string;
  onClick(): void;
  disabled: boolean;
};

type MainButtonState = {
  text: string;
  progress: boolean;
  shown: boolean;
  color: string;
  onClick(): void;
};

type ButtonLayer<State> = {
  set(state: Partial<State>): void;
  unregister(): void;
};

export interface ITelegramService {
  $theme: Atom<TelegramTheme>;
  connect(): void;
  ready(): void;
  expand(): void;

  registerBackButtonState(): ButtonLayer<BackButtonState>;
  registerMainButtonState(): ButtonLayer<MainButtonState>;
}

export class TelegramService implements ITelegramService {
  public $theme = new Atom<TelegramTheme>(defaultTheme);

  private backButtonLayers: Partial<BackButtonState>[] = [];
  private mainButtonLayers: Partial<MainButtonState>[] = [];

  constructor(private webapp: WebApp) {}

  connect() {
    this.$theme.set(this.webapp.themeParams);

    this.webapp.onEvent("themeChanged", () => {
      this.$theme.set({ ...this.webapp.themeParams });
    });

    this.webapp.BackButton.onClick(() => this.clickBackButton());
    this.webapp.MainButton.onClick(() => this.clickMainButton());
  }

  clickMainButton() {
    for (const state of this.mainButtonLayers) {
      if (state.onClick) {
        state.onClick();
        break;
      }
    }
  }

  clickBackButton() {
    for (const state of this.backButtonLayers) {
      if (state.onClick) {
        state.onClick();
        break;
      }
    }
  }

  ready() {
    this.webapp.ready();
  }

  expand() {
    this.webapp.expand();
  }

  registerBackButtonState(): ButtonLayer<BackButtonState> {
    let state: Partial<BackButtonState> = {};
    this.backButtonLayers.unshift(state);

    return {
      set: patch => {
        Object.assign(state, patch);
        this.applyBackButtonLayers();
      },
      unregister: () => {
        const index = this.backButtonLayers.findIndex(x => x === state);
        this.backButtonLayers.splice(index, 1);
        this.applyBackButtonLayers();
      },
    };
  }

  registerMainButtonState(): ButtonLayer<MainButtonState> {
    let state: Partial<MainButtonState> = {};
    this.mainButtonLayers.unshift(state);

    return {
      set: patch => {
        Object.assign(state, patch);
        this.applyMainButtonLayers();
      },
      unregister: () => {
        const index = this.mainButtonLayers.findIndex(x => x === state);
        this.mainButtonLayers.splice(index, 1);
        this.applyMainButtonLayers();
      },
    };
  }

  private applyBackButtonLayers = debounce(() => {
    const shown = this.backButtonLayers.some(s => s.onClick);
    const text = this.backButtonLayers.find(l => l.text)?.text;
    const disabled = this.backButtonLayers.some(l => l.disabled);

    this.webapp.BackButton.setDisabled?.(disabled);

    if (shown) {
      this.webapp.BackButton.show();
    } else {
      this.webapp.BackButton.hide();
    }

    if (text) {
      this.webapp.BackButton.setText?.(text);
    }
  }, 16); // one frame

  private applyMainButtonLayers = debounce(() => {
    const initialState = {
      text: "...",
      progress: false,
      shown: false,
      color: this.webapp.themeParams.button_color,
    };

    const state = this.mainButtonLayers.reduce(
      (state: typeof initialState, layer) => ({
        text: layer.text ?? state.text,
        progress: layer.progress ?? state.progress,
        shown: layer.shown ?? state.shown,
        color: layer.color ?? state.color,
      }),
      initialState,
    );

    this.webapp.MainButton.setText(state.text);

    if (state.progress) {
      this.webapp.MainButton.showProgress();
    } else {
      this.webapp.MainButton.hideProgress();
    }

    this.webapp.MainButton.setParams({ color: state.color });

    if (state.shown) {
      this.webapp.MainButton.show();
    } else {
      this.webapp.MainButton.hide();
    }
  }, 16);
}

export const TelegramServiceContext = createContext<ITelegramService | null>(null);

export function useTelegramService(): ITelegramService {
  const service = useContext(TelegramServiceContext);
  if (service === null) throw new Error("Wrap with TelegramServiceContext.Provider");
  return service;
}

export function useTelegramTheme(defaultTheme: TelegramTheme): TelegramTheme {
  const telegramService = useTelegramService();
  return useSubscribe(telegramService.$theme, defaultTheme);
}

export function useTelegramBackButton(state?: Partial<BackButtonState>) {
  const telegram = useTelegramService();
  const layer = useRef<ButtonLayer<BackButtonState> | undefined>();

  useEffect(() => {
    if (state) {
      layer.current = telegram.registerBackButtonState();
      return () => layer.current?.unregister();
    }
  }, [state]);

  useEffect(() => {
    if (state) layer.current?.set(state);
  }, [state]);
}

export function useTelegramMainButton(state: Partial<MainButtonState>) {
  const telegram = useTelegramService();
  const layer = useRef<ButtonLayer<MainButtonState>>();

  useEffect(() => {
    layer.current = telegram.registerMainButtonState();
    return () => layer.current?.unregister();
  }, []);

  useEffect(() => {
    layer.current?.set(state);
  }, [state]);
}
