import React from "react";
import styled, { css } from "styled-components";
import { transparentize } from "polished";
import { hintSkeleton } from "../../styled-mixins/skeleton";

import { IconFrame } from "../../components/icon-frame";

type Props = {
  banks: undefined | null | string;
  icon: React.ReactNode;
  label: React.ReactNode;
  title: React.ReactNode;
  onClick(): void;
};

export function FiatButton(props: Props) {
  return (
    <ListContainer>
      <Label>{props.label}</Label>
      <PayWithCardContainer
        as={props.banks === undefined ? "div" : "button"}
        onClick={props.banks === undefined ? undefined : props.onClick}
        $clickable={props.banks !== undefined}
        $banks={props.banks !== null}
      >
        <PayWithCardIcon>
          <IconFrame size={40}>{props.icon}</IconFrame>
        </PayWithCardIcon>
        <PayWithCardTitle>{props.title}</PayWithCardTitle>
        {props.banks !== null && (
          <PayWithCardDesc>
            {props.banks === undefined ? (
              <TextSkeleton>LONG LIST OF BANKS</TextSkeleton>
            ) : (
              props.banks
            )}
          </PayWithCardDesc>
        )}
      </PayWithCardContainer>
    </ListContainer>
  );
}

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.div`
  padding-left: 6px;

  color: ${props => props.theme.hint};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.24px;
  text-transform: uppercase;
`;

const PayWithCardContainer = styled.button<{ $clickable?: boolean; $banks?: boolean }>`
  display: grid;
  grid-template:
    "icon title"
    "icon ${props => (props.$banks ? "desc" : "title")}"
    / min-content 1fr;
  column-gap: 10px;
  align-items: center;

  border: 0;
  background: none;
  text-align: left;

  padding: 16px;

  margin: 0 -4px;
  border-radius: 20px;
  background: ${props => transparentize(0.9, props.theme.button)};

  cursor: not-allowed;
  ${props =>
    props.$clickable &&
    css`
      cursor: pointer;

      &:hover {
        background: ${transparentize(0.8, props.theme.button)};
      }
    `}
`;

const PayWithCardIcon = styled.div`
  grid-area: icon;
`;

const PayWithCardTitle = styled.div`
  grid-area: title;

  color: ${props => props.theme.text};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3125rem;
  letter-spacing: 0.32px;
`;

const PayWithCardDesc = styled.div`
  grid-area: desc;

  color: ${props => props.theme.hint};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.1875rem;
`;

const TextSkeleton = styled.span`
  ${hintSkeleton};
`;
