import WebSocket from "reconnecting-websocket";
import { PathReporter } from "io-ts/PathReporter";
import { isLeft } from "fp-ts/Either";
import { Atom } from "lib/event-bus";
import { createContext, useContext } from "react";
import { useAtomSubscribe } from "hooks/use-subscribe";

import { Balance } from "models";
import { call, signature } from "lib/message-server";

export class UnknownBalanceMessage extends Error {
  name = "UnknownBalanceMessage";
}

const _getBalance_ = signature<void, Balance | undefined>("jetton/get-balance");

export interface IBalanceService extends BalanceService {}

export class BalanceService extends Atom<Balance | null> {
  private connection?: WebSocket;

  constructor(
    private wsUrl: string,
    private token: string,
  ) {
    super(null);
  }

  public connect() {
    call(_getBalance_, undefined).then(balance => {
      console.log("balance from parent");
      if (this.value === null && balance) {
        this.set(balance);
      }
    });

    const connection = new WebSocket(this.wsUrl + "/wallet/balance");

    connection.addEventListener("open", () => {
      connection.send(this.token);

      setTimeout(() => this.connection?.reconnect(), 1000 * 30);
    });

    connection.addEventListener("message", event => {
      const message = Balance.decode(JSON.parse(event.data));
      if (isLeft(message)) {
        throw new UnknownBalanceMessage(PathReporter.report(message).join("\n\n") + "\n\n");
      } else {
        console.log("balance from socket");
        this.set(message.right);
      }
    });

    this.connection = connection;
  }

  public disconnect() {
    this.connection?.close();
  }

  public reconnect() {
    this.connection?.reconnect();
  }
}

export const BalanceServiceContext = createContext<null | IBalanceService>(null);

export function useBalanceService(): IBalanceService {
  const service = useContext(BalanceServiceContext);
  if (service === null) throw new Error("Wrap with BalanceServiceContext.Provider");
  return service;
}

export function useBalance(): null | Balance {
  const balanceService = useBalanceService();
  return useAtomSubscribe(balanceService);
}
