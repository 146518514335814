import usePromise_ from "react-use-promise";
import { useState } from "hooks/use-state";
import { useEffect } from "react";

type Handler<T, G> = {
  pending(): G;
  resolved(value: T): G;
  rejected(error: Error): G;
};

type PromiseResultAPI<T> = {
  match<G>(handler: Handler<T, G>): G;
};

type PromiseState<T> = PromiseResultAPI<T> &
  (
    | { resolved: true; value: T; error: undefined }
    | { resolved: false; value: undefined; error: Error | undefined }
  );

export function usePromise<T>(mkPromise: () => Promise<T>, deps: unknown[]): PromiseState<T> {
  const promiseValue = usePromise_(mkPromise, deps);

  function match<G>(handler: Handler<T, G>) {
    switch (promiseValue[2]) {
      case "pending":
        return handler.pending();
      case "rejected":
        return handler.rejected(promiseValue[1]);
      case "resolved":
        return handler.resolved(promiseValue[0]);
    }
  }

  return match<PromiseState<T>>({
    pending() {
      return { match, resolved: false, value: undefined, error: undefined };
    },
    resolved(value) {
      return { match, resolved: true, value: value, error: undefined };
    },
    rejected(error) {
      return { match, resolved: false, error, value: undefined };
    },
  });
}

export function usePromiseLatest<T>(f: () => Promise<T>, deps: unknown[]): undefined | T {
  const value = useState<undefined | T>(undefined);

  useEffect(() => {
    f().then(value.set, _ => {});
  }, deps);

  return value.get;
}
