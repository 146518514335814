import { createContext, useContext } from "react";

export class ConfigService {
  constructor(
    public env: { API: string; API_WS: string; DEPOSIT_TONKEEPER: string; DEPOSIT_TONHUB: string },
    public query: { token: string; tonConnect: string | false; tgp: boolean }
  ) {}

  makeTonKeeperURL(address: string): string {
    return this.env.DEPOSIT_TONKEEPER.replace("{address}", address);
  }

  makeTonHubURL(address: string): string {
    return this.env.DEPOSIT_TONHUB.replace("{address}", address);
  }

  hidePaymentProviders(): boolean {
    const isIFrame = window.parent && window.parent !== window;
    return isIFrame;
  }
}

export const ConfigServiceContext = createContext<null | ConfigService>(null);

export function useConfigService(): ConfigService {
  const service = useContext(ConfigServiceContext);
  if (service === null) throw new Error("Wrap with ConfigServiceContext.Provider");
  return service;
}
