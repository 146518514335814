import { useEffect, useMemo } from "react";
import { routes } from "routes";

import { Balance, Transaction, Currency } from "models";

import { useNavigate } from "hooks/use-navigate";
import { useBalance } from "services/balance";
import { useTranslation } from "services/i18n";
import { useTransactions, useTransactionsService } from "services/transactions";
import { useTrack } from "services/track";
import { useState } from "hooks/use-state";
import { useCurrencies, useOrderCurrencies } from "services/currencies";
import { useApiService } from "services/api";

import { Tabs, useTabs } from "components/tabs";
import { BalanceView } from "./balance";
import { TabDeposit } from "./tab-deposit";
import { TabWithdraw } from "./tab-withdraw";
import { TabTransactions } from "./tab-transactions";
import { FirstDepositView } from "../first-deposit";

import { IconFrame } from "components/icon-frame";
import { ReactComponent as BuyCryptoIcon } from "icons/buy-crypto.svg";
import { ReactComponent as BuyFiatIcon } from "icons/buy-fiat.svg";

import * as S from "./main.styled";

let redirected = false;

export type MainPageProps = {
  initialTab?: "txns" | "deposit" | "withdraw";
};

export function MainPage(props: MainPageProps) {
  const track = useTrack();
  const api = useApiService();
  const navigate = useNavigate();
  const balance = useBalance();
  const transactions = useTransactions();
  const transactionsService = useTransactionsService();
  const currencies = useCurrencies();
  const currenciesForFiatPayment = useOrderCurrencies();

  useEffect(() => {
    transactionsService.initial?.then(transactions => {
      if (!redirected && transactions.length > 0 && transactions[0].showWaiting === true) {
        redirected = true;
        navigate(routes.buy.payment({ id: transactions[0].id }));
      }
    });
  }, []);

  const newUser = balance?.empty === true;

  function handleOpenTransaction(transaction: Transaction) {
    if (transaction.type === "buy") {
      navigate(routes.buy.transaction({ id: transaction.id }));
    } else {
      navigate(routes.transaction({ id: transaction.id }));
    }
  }

  async function handleCancelTransaction(transaction: Transaction) {
    switch (transaction.type) {
      case "buy":
        return await api.cancelBuyOrder(transaction.id);
      case "sell":
        return await api.cancelSellOrder(transaction.id);
      case "deposit":
      case "withdraw":
        return await api.cancelTransaction(transaction.id);
      default:
        return transaction satisfies never;
    }
  }

  function handleDeposit(currency?: Currency["code"]) {
    if (currency) {
      navigate(routes.deposit.index({ currency }));
    } else {
      track("[Wallet] Deposit: open");
      navigate(routes.deposit.currency());
    }
  }

  if (balance?.empty) {
    return (
      <FirstDepositView
        onCrypto={() => navigate(routes.deposit.currency())}
        onFiat={() => navigate(routes.buy.amount())}
      />
    );
  }

  return (
    <MainView
      balance={balance ?? undefined}
      currencies={currencies}
      currenciesForFiatPayment={currenciesForFiatPayment}
      transactions={transactions}
      newUser={newUser}
      onDeposit={handleDeposit}
      initialTab={props.initialTab}
      onWithdraw={currency => {
        track("[Wallet] Withdraw: currency selected", { currency });
        navigate(routes.withdraw.address({ currency }));
      }}
      onBuy={() => {
        track("[Wallet] Buy: open");
        navigate(routes.buy.amount());
      }}
      onSell={() => {
        track("[Wallet] Sell: open");
        navigate(routes.sell.amount());
      }}
      onTransactionClick={handleOpenTransaction}
      onTransactionCancel={handleCancelTransaction}
      onBalanceSettingsClick={() => navigate(routes.settings())}
    />
  );
}

type ViewProps = {
  balance?: Balance;
  currencies?: Currency[];
  currenciesForFiatPayment?: (Currency & { topBanks: string; valuePresets: number[] })[];
  transactions: null | Transaction[];
  newUser?: boolean;
  initialTab?: "txns" | "deposit" | "withdraw";
  onDeposit(code?: Currency["code"]): void;
  onWithdraw(code: Currency["code"]): void;
  onBuy(): void;
  onSell(): void;
  onTransactionClick(transaction: Transaction): void;
  onTransactionCancel(transaction: Transaction): Promise<void>;
  onBalanceSettingsClick?(): void;
};

export function MainView({
  balance,
  currencies,
  currenciesForFiatPayment,
  transactions,
  initialTab,
  newUser,
  onDeposit,
  onWithdraw,
  onBuy,
  onSell,
  onTransactionClick,
  onTransactionCancel,
  onBalanceSettingsClick,
}: ViewProps) {
  const t = useTranslation();

  const activeTab = useState<"txns" | "deposit" | "withdraw">(initialTab ?? "deposit");

  const tabs = useTabs(activeTab, [
    { id: "deposit" as const, label: t("ui", "wallet_tab_deposit") },
    { id: "withdraw" as const, label: t("ui", "wallet_tab_withdraw") },
    { id: "txns" as const, label: t("ui", "wallet_tab_txns") },
  ]);

  const fiatBanks = useMemo(() => {
    if (!balance) return undefined;
    if (!currenciesForFiatPayment) return undefined;

    return (
      currenciesForFiatPayment.find(c => c.code === balance.fiat.currency.code)?.topBanks ?? null
    );
  }, [balance?.fiat.currency.code, currenciesForFiatPayment]);

  return (
    <S.Container>
      <S.AppContainer style={{ flexGrow: newUser ? 0 : 1 }}>
        <S.GlobalStyles />
        <BalanceView balance={balance} onClick={onBalanceSettingsClick} />

        {!newUser && (
          <>
            <S.TabsContainer>
              <Tabs tabs={tabs} />
            </S.TabsContainer>
            {tabs.match({
              deposit: (
                <TabDeposit
                  currencies={currencies?.filter(currency => !currency.isFiat)}
                  onDepositWithCard={onBuy}
                  onDepositWithCrypto={onDeposit}
                  fiatBanks={fiatBanks}
                />
              ),
              withdraw: (
                <TabWithdraw
                  currencies={currencies?.filter(currency => currency.withdrawAllowed)}
                  onWithdrawWithCard={onSell}
                  onWithdrawWithCrypto={onWithdraw}
                  fiatBanks={fiatBanks}
                />
              ),
              txns: (
                <TabTransactions
                  transactions={transactions}
                  onClick={onTransactionClick}
                  onCancel={onTransactionCancel}
                />
              ),
            })}
          </>
        )}
      </S.AppContainer>
      {newUser && (
        <S.NewUserContainer>
          <S.Label>{t("ui", "wallet_new_user_payment_method")}</S.Label>
          <S.NewUserButton onClick={onBuy}>
            <IconFrame size={40}>
              <BuyFiatIcon />
            </IconFrame>
            <S.NewUserButtonLabel>
              {t("ui", "wallet_new_user_payment_method_fiat")}
            </S.NewUserButtonLabel>
            <S.NewUserButtonArrow />
          </S.NewUserButton>
          <S.NewUserButton onClick={() => onDeposit()}>
            <IconFrame size={40}>
              <BuyCryptoIcon />
            </IconFrame>
            <S.NewUserButtonLabel>
              {t("ui", "wallet_new_user_payment_method_crypto")}
            </S.NewUserButtonLabel>
            <S.NewUserButtonArrow />
          </S.NewUserButton>
        </S.NewUserContainer>
      )}
    </S.Container>
  );
}
