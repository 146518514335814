import { State } from "hooks/use-state";
import { transparentize } from "polished";
import styled from "styled-components";

type Option = {
  id: string;
  label: React.ReactNode;
};

type TabsState<O extends Option[]> = {
  options: O;
  active: Option["id"];
  select(value: Option["id"]): void;
  match<T>(cases: Record<O[number]["id"], T>): T;
};

export function useTabs<O extends Option[]>(
  state: State<O[number]["id"]>,
  options: O
): TabsState<O> {
  return { options, active: state.get, select: state.set, match: cases => cases[state.get] };
}

export function Tabs<O extends Option[]>({ tabs }: { tabs: TabsState<O> }) {
  return (
    <Container>
      {tabs.options.map(option => (
        <Tab
          key={option.id}
          $active={option.id === tabs.active}
          onClick={() => tabs.select(option.id)}
        >
          {option.label}
        </Tab>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;

  width: 100%;

  border-bottom: 1px solid ${props => transparentize(0.9, props.theme.hint)};
`;

const Tab = styled.button<{ $active: boolean }>`
  color: ${props => props.theme.text};

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  background: none;
  border: 0;
  cursor: pointer;

  padding: 10px 12px;

  margin-bottom: 0;

  border-bottom: 2px solid ${props => (props.$active ? props.theme.button : "transparent")};
  opacity: ${props => (props.$active ? 1 : 0.8)};

  text-overflow: ellipsis;
  overflow: hidden;
`;
