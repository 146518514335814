import styled from "styled-components";
import { hintSkeleton } from "styled-mixins/skeleton";
import { ReactComponent as CheckmarkIcon } from "icons/checkmark.svg";
import { transparentize } from "polished";

type SelectListProps = {
  header?: React.ReactNode;
  secondary?: boolean;
  children: React.ReactNode;
};

export function SelectList({ header, secondary, children }: SelectListProps) {
  return (
    <Container>
      {header && <Header>{header}</Header>}
      <List $secondary={secondary}>{children}</List>
    </Container>
  );
}

type SelectListOptionProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
  checked?: boolean;
  onCheck?(): void;
};

export function SelectListOption({ icon, children, checked, onCheck }: SelectListOptionProps) {
  return (
    <Item onClick={onCheck}>
      <Icon>{icon}</Icon>
      <Name>{children}</Name>
      <Selected $visible={checked} />
    </Item>
  );
}

export function SelectListSkeleton({ children }: { children: React.ReactNode }) {
  return (
    <Item>
      <IconSkeleton />
      <Name>
        <TextSkeleton>{children}</TextSkeleton>
      </Name>
    </Item>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4px;

  margin: 0 -4px;
`;

const Header = styled.div`
  padding: 0 4px;

  color: ${props => props.theme.hint};
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.125rem;
  text-transform: uppercase;
`;

const List = styled.div<{ $secondary?: boolean }>`
  padding: 8px 0;
  border-radius: 20px;
  background: ${props => transparentize(0.9, props.theme.button)};
`;

const Item = styled.button.attrs({ type: "button" })`
  display: flex;
  flex-direction: row;
  gap: 10px;

  width: 100%;

  align-items: center;

  padding: 8px 16px 8px 8px;

  border: 0;
  background: none;
  text-align: left;

  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${props => transparentize(0.9, props.theme.link)};
  }
`;

const Icon = styled.div`
  width: 32px;
  height: 32px;
`;

const Name = styled.div`
  color: ${props => props.theme.text};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;

  flex-grow: 1;
`;

const Selected = styled(CheckmarkIcon)<{ $visible?: boolean }>`
  width: 22px;
  height: 22px;
  color: ${props => props.theme.button};
  opacity: ${props => (props.$visible ? 1 : 0)};
`;

const IconSkeleton = styled.div`
  width: 32px;
  height: 32px;

  ${hintSkeleton};

  border-radius: 100%;
`;

const TextSkeleton = styled.span`
  ${hintSkeleton};
`;
