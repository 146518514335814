import { routes } from "routes";
import { BuyOrder } from "shared/models/buy";

export function reroute(order: BuyOrder): string {
  switch (order.status) {
    case "enter_amount":
      return routes.buy.amount();
    case "select_bank":
      return routes.buy.bank(order);
    case "pay_invoice":
      return routes.buy.payment(order);
    case "wait":
    case "wait_for_confirmation":
    case "timeouted":
    case "completed":
    case "rejected":
    case "disputed":
      return routes.buy.status(order);
    case "pay_external_invoice":
      return routes.buy.externalPayment(order);
    case "enter_external_details":
      return routes.buy.externalDetails(order);
    default:
      void (order satisfies never);
      throw order;
  }
}
