import type { WebApp } from ".";

export function getWebApp(): WebApp | null {
  const webapp = window.Telegram?.WebApp ?? null;
  if (webapp?.themeParams.bg_color == null) {
    return null;
  } else {
    return webapp;
  }
}
