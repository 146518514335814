export function debounce<Args extends unknown[], Result>(
  f: (...args: Args) => Result,
  timeout: number
): (...args: Args) => Promise<Result> {
  let timeoutInstance: null | NodeJS.Timeout = null;

  return (...args: Args) => {
    return new Promise(resolve => {
      if (timeoutInstance) clearTimeout(timeoutInstance);

      timeoutInstance = setTimeout(() => {
        resolve(f(...args));
      }, timeout);
    });
  };
}
