import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { useEffect, useMemo } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { routes } from "routes";

import { fromTelegramTheme, defaultTheme } from "theme";
import { NotificationsOverlay } from "components/notifications";
import { TelegramService, useTelegramService, useTelegramTheme } from "services/telegram";
import { useNotificationsService } from "services/notifications";
import { useTransactionsService } from "services/transactions";

import { MainPage } from "pages/main";
import { useTranslation } from "./services/i18n";
import { customScrollbars } from "./styled-mixins/custom-scrollbar";

function makeRouter() {
  return createBrowserRouter([
    {
      path: routes.main(),
      element: <MainPage />,
    },
    {
      path: routes.transactions(),
      element: <MainPage initialTab={"txns"} />,
    },
    {
      path: routes.settings(),
      async lazy() {
        const { SettingsPage } = await import("pages/settings");
        return { Component: SettingsPage };
      },
    },
    {
      path: routes.transaction({ id: ":id" }),
      async lazy() {
        const { TransactionPage } = await import("pages/transaction");
        return { Component: TransactionPage };
      },
    },
    {
      path: routes.deposit.currency(),
      async lazy() {
        const { DepositCurrencyPage } = await import("pages/deposit/currency");
        return { Component: DepositCurrencyPage };
      },
    },
    {
      path: routes.deposit.index({ currency: ":currency" }),
      async lazy() {
        const { DepositPage } = await import("pages/deposit");
        return { Component: DepositPage };
      },
    },
    {
      path: routes.withdraw.address({ currency: ":currency" }),
      async lazy() {
        const { WithdrawAddressPage } = await import("pages/withdraw/address");
        return { Component: WithdrawAddressPage };
      },
    },
    {
      path: routes.deposit.amount({ currency: ":currency" }),
      async lazy() {
        const { DepositAmountPage } = await import("pages/deposit/amount");
        return { Component: DepositAmountPage };
      },
    },
    {
      path: routes.deposit.status({ currency: ":currency", value: ":value" }),
      async lazy() {
        const { DepositStatusPage } = await import("pages/deposit/status");
        return { Component: DepositStatusPage };
      },
    },
    {
      path: routes.depositTonConnect.amount({ currency: ":currency" }),
      async lazy() {
        const { TonConnectAmountPage } = await import("pages/deposit/ton-connect-amount");
        return { Component: TonConnectAmountPage };
      },
    },
    {
      path: routes.withdraw.amount({
        currency: ":currency",
        amount: ":amount",
        address: ":address",
      }),
      async lazy() {
        const { WithdrawAmountPage } = await import("pages/withdraw/amount");
        return { Component: WithdrawAmountPage };
      },
    },
    {
      path: routes.withdrawTonConnect.amount({ amount: ":amount", currency: ":currency" }),
      async lazy() {
        const { TonConnectAmountPage } = await import(
          "pages/withdraw-ton-connect/ton-connect-amount/ton-connect-amount"
        );
        return { Component: TonConnectAmountPage };
      },
    },
    {
      path: routes.withdrawTonConnect.confirm({
        fee: ":fee",
        amount: ":amount",
        currency: ":currency",
      }),
      async lazy() {
        const { WithdrawTonConnectConfirmPage } = await import(
          "pages/withdraw-ton-connect/ton-connect-confirm/ton-connect-confirm"
        );
        return { Component: WithdrawTonConnectConfirmPage };
      },
    },
    {
      path: routes.withdrawTonConnect.success({
        amount: ":amount",
        currency: ":currency"
      }),
      async lazy() {
        const { WithdrawTonConnectSuccessPage } = await import(
          "pages/withdraw-ton-connect/success"
        );
        return { Component: WithdrawTonConnectSuccessPage };
      },
    },
    {
      path: routes.withdraw.confirm({
        currency: ":currency",
        address: ":address",
        fee: ":fee",
        amount: ":amount",
      }),
      async lazy() {
        const { WithdrawConfirmPage } = await import("pages/withdraw/confirm");
        return { Component: WithdrawConfirmPage };
      },
    },
    {
      path: routes.withdraw.success({
        currency: ":currency",
        address: ":address",
        amount: ":amount",
      }),
      async lazy() {
        const { WithdrawSuccessPage } = await import("pages/withdraw/success");
        return { Component: WithdrawSuccessPage };
      },
    },
    {
      path: routes.buy.amount(),
      async lazy() {
        const { BuyAmountPage } = await import("pages/buy/amount");
        return { Component: BuyAmountPage };
      },
    },
    {
      path: routes.buy.bank({ id: ":id" }),
      async lazy() {
        const { BuyBankPage } = await import("pages/buy/bank");
        return { Component: BuyBankPage };
      },
    },
    {
      path: routes.buy.payment({ id: ":id" }),
      async lazy() {
        const { BuyPaymentPage } = await import("pages/buy/payment");
        return { Component: BuyPaymentPage };
      },
    },
    {
      path: routes.buy.externalPayment({ id: ":id" }),
      async lazy() {
        const { BuyExternalPaymentPage } = await import("pages/buy/external-payment");
        return { Component: BuyExternalPaymentPage };
      },
    },
    {
      path: routes.buy.externalDetails({ id: ":id" }),
      async lazy() {
        const { BuyExternalDetailsPage } = await import(
          "pages/buy/external-details/external-details"
        );
        return { Component: BuyExternalDetailsPage };
      },
    },
    {
      path: routes.buy.confirmation({ id: ":id" }),
      async lazy() {
        const { BuyConfirmationPage } = await import("pages/buy/confirmation");
        return { Component: BuyConfirmationPage };
      },
    },
    {
      path: routes.buy.status({ id: ":id" }),
      async lazy() {
        const { BuyStatusPage } = await import("pages/buy/status");
        return { Component: BuyStatusPage };
      },
    },
    {
      path: routes.buy.dispute({ id: ":id" }),
      async lazy() {
        const { BuyDisputePage } = await import("pages/buy/dispute");
        return { Component: BuyDisputePage };
      },
    },
    {
      path: routes.buy.transaction({ id: ":id" }),
      async lazy() {
        const { BuyTransactionPage } = await import("pages/buy/transaction");
        return { Component: BuyTransactionPage };
      },
    },
    {
      path: routes.sell.amount(),
      async lazy() {
        const { SellAmountPage } = await import("pages/sell/amount");
        return { Component: SellAmountPage };
      },
    },
    {
      path: routes.sell.bank({ id: ":id" }),
      async lazy() {
        const { SellBankPage } = await import("pages/sell/bank");
        return { Component: SellBankPage };
      },
    },
    {
      path: routes.sell.payment({ id: ":id" }),
      async lazy() {
        const { SellPaymentPage } = await import("pages/sell/payment");
        return { Component: SellPaymentPage };
      },
    },
    {
      path: routes.sell.externalDetails({ id: ":id" }),
      async lazy() {
        const { SellExternalDetails } = await import("pages/sell/external-details");
        return { Component: SellExternalDetails };
      },
    },
    {
      path: routes.sell.confirm({ id: ":id" }),
      async lazy() {
        const { SellConfirmPage } = await import("pages/sell/confirm");
        return { Component: SellConfirmPage };
      },
    },
    {
      path: routes.sell.status({ id: ":id" }),
      async lazy() {
        const { SellStatusPage } = await import("pages/sell/status");
        return { Component: SellStatusPage };
      },
    },
  ]);
}

export function App() {
  const t = useTranslation();
  const theme = useTelegramTheme(defaultTheme);
  const router = useMemo(makeRouter, []);
  const notifications = useNotificationsService();
  const transactions = useTransactionsService();
  const telegram = useTelegramService();

  useEffect(() => {
    return transactions.subscribe(transaction => {
      if (transaction.type === "deposit" && transaction.status === "completed") {
        notifications.notify({
          message: t("ui", "wallet_deposit_notification", {
            amount: transaction.amount.value,
            currency: transaction.amount.currency.code,
          }),
        });
      }

      if (transaction.type === "withdraw" && transaction.status === "completed") {
        notifications.notify({
          message: t("ui", "wallet_withdraw_transaction_success_notification"),
        });
      }
    });
  }, []);

  useEffect(() => {
    Object.assign(window, {
      main_button() {
        (telegram as TelegramService).clickMainButton?.();
      },
      back_button() {
        (telegram as TelegramService).clickBackButton?.();
      },
    });
  }, []);

  return (
    <AppContainer>
      <ThemeProvider theme={fromTelegramTheme(theme)}>
        <WithTelegramTheme />
        <NotificationsOverlay ttl={3} />
        <RouterProvider router={router} />
      </ThemeProvider>
    </AppContainer>
  );
}

const WithTelegramTheme = createGlobalStyle`
    body, html {
        height: 100vh;
        background-color: ${props => props.theme.bg};
        ${customScrollbars};
    }
`;

const AppContainer = styled.div`
  padding: 16px 20px;
`;
