import { Currency } from "../models";
import { Atom } from "../lib/event-bus";
import { ApiService } from "./api";
import { createContext, useContext } from "react";
import { useAtomSubscribe } from "../hooks/use-subscribe";

export type OrderCurrency = Currency & { topBanks: string; valuePresets: number[] };

export class CurrenciesService {
  public currencies = new Atom<Currency[]>([]);
  public orderCurrencies = new Atom<OrderCurrency[]>([]);

  constructor(public api: ApiService) {}

  public async mount() {
    await Promise.all([
      this.api.getCurrencies().then(currencies => this.currencies.set(currencies)),
      this.api.getOrderCurrencies().then(currencies => this.orderCurrencies.set(currencies)),
    ]);
  }
}

export const CurrenciesServiceContext = createContext<null | CurrenciesService>(null);

export function useCurrenciesService(): CurrenciesService {
  const service = useContext(CurrenciesServiceContext);
  if (service === null) throw new Error("Wrap with CurrenciesServiceContext.Provider");
  return service;
}

export function useCurrencies(): Currency[] {
  return useAtomSubscribe(useCurrenciesService().currencies);
}

export function useOrderCurrencies(): OrderCurrency[] {
  return useAtomSubscribe(useCurrenciesService().orderCurrencies);
}

export function useCurrency(code?: Currency["code"]): Currency | undefined {
  return useCurrencies().find(c => c.code === code);
}

export function useOrderCurrency(code?: Currency["code"]): OrderCurrency | undefined {
  const orderCurrencies = useAtomSubscribe(useCurrenciesService().orderCurrencies);
  return orderCurrencies.find(c => c.code === code);
}
