import { createContext, useContext } from "react";
import { Atom } from "lib/event-bus";
import type { IApiService, Vocabulary } from "./api";
import { render } from "lib/render";
import { call, signature } from "lib/message-server";

export { type Vocabulary };
export type VocabularyNamespace = Exclude<keyof Vocabulary, "language">;

export type LanguageCode = string;

const defaultVocabulary: Record<LanguageCode, Vocabulary> = {
  en: {
    language: "en",
    ui: {},
    errors: {
      not_browser_webapp_line_1: "This is a Telegram WebApp",
      not_browser_webapp_line_2: "Please, don't launch it in browser",
      not_configured: "Environment is not provided",
      something_wrong: "Something went wrong",
      error_401_unauthorized_invalid_token_1: "Sorry, you can't be authorized",
      error_401_unauthorized_invalid_token_2: "You should restart the bot with /start",
      error_401_unauthorized_banned_1: "Sorry, you can't be authorized",
      error_401_unauthorized_banned_2: "You are banned",
      error_401_unauthorized_adult_1: "You are not allowed to use that functionality",
      error_401_unauthorized_adult_2: "",
    },
  },
  ru: {
    language: "ru",
    ui: {},
    errors: {
      not_browser_webapp_line_1: "Это Telegram WebApp",
      not_browser_webapp_line_2: "Не стоит запускать его в браузере",
      not_configured: "Окружение не настроено",
      something_wrong: "Что-то пошло не так",
      error_401_unauthorized_invalid_token_1: "Вы не можете быть авторизованы",
      error_401_unauthorized_invalid_token_2: "Перезапустите бота командой /start",
      error_401_unauthorized_banned_1: "Вы заблокированы",
      error_401_unauthorized_banned_2: "",
      error_401_unauthorized_adult_1: "Вы не можете пользоваться данным фукционалом",
      error_401_unauthorized_adult_2: "",
    },
  },
};

export class I18nError extends Error {
  name = "I18nError";
  constructor(public error: unknown) {
    super();
  }
}

export interface II18nService {
  $vocabulary: Atom<Vocabulary>;
  translate(
    vocabulary: Vocabulary,
    ns: VocabularyNamespace,
    key: string,
    context?: Record<string, string | number>,
  ): string;

  date(value: number | Date): string;
}

const _getVocabulary_ = signature<void, Vocabulary | undefined>("jetton/get-vocabulary");

export class I18nService implements II18nService {
  public languageCode = this.getUserLocale();
  public $vocabulary: Atom<Vocabulary> = new Atom(
    defaultVocabulary[this.languageCode] ?? defaultVocabulary["en"],
  );

  getUserLocale() {
    const queryLanguage = new URLSearchParams(window.location.search).get("locale");
    if (queryLanguage) return queryLanguage;

    const language: string = navigator.language || (navigator as any).userLanguage;

    if (language === "ru" || language.includes("ru-") || language.includes("-RU")) {
      return "ru";
    } else {
      return "en";
    }
  }

  async connectFromParent() {
    const vocabulary = await call(_getVocabulary_, undefined);
    if (vocabulary) {
      console.log("vocabulary from parent");
      this.$vocabulary.set(vocabulary);
      this.languageCode = vocabulary.language;
    }
  }

  async connectFromAPI(api: IApiService) {
    try {
      const vocabulary = await api.getI18n(this.getUserLocale());
      console.log("vocabulary from api");
      this.$vocabulary.set(vocabulary);
      this.languageCode = vocabulary.language;
    } catch (e) {
      throw new I18nError(e);
    }
  }

  async connect(api: IApiService) {
    return await Promise.race([
      //
      this.connectFromParent(),
      this.connectFromAPI(api),
    ]);
  }

  public translate(
    vocabulary: Vocabulary,
    ns: VocabularyNamespace,
    key: string,
    context: Record<string, string | number> = {},
  ): string {
    return render(lookup(vocabulary, ns, key), context);
  }

  public translateWithFallback: Translation = (
    ...args:
      | [ns: VocabularyNamespace, key: string, context?: Record<string, string | number>]
      | [date: number | Date]
  ) => {
    const vocabulary = this.$vocabulary.value ?? defaultVocabulary["en"];
    if (typeof args[0] === "string") {
      return this.translate(vocabulary, args[0], args[1], args[2]);
    } else {
      return this.date(args[0]);
    }
  };

  public date(value: number | Date) {
    return new Date(value).toLocaleString(this.languageCode, {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  }
}

function lookup(vocabulary: Vocabulary, ns: VocabularyNamespace, key: string): string {
  const value = vocabulary?.[ns]?.[key];
  return typeof value === "string" ? value : key;
}

export const I18nContext = createContext(new I18nService());

export interface Translation {
  (ns: VocabularyNamespace, key: string, context?: Record<string, string | number>): string;
  (date: number | Date): string;
}

export function useTranslation(): Translation {
  const i18n = useContext(I18nContext);
  return i18n.translateWithFallback.bind(i18n);
}
