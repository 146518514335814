const iOSDevices = [
  "iPad Simulator",
  "iPhone Simulator",
  "iPod Simulator",
  "iPad",
  "iPhone",
  "iPod",
];

const ios =
  iOSDevices.includes(navigator.platform) ||
  (navigator.userAgent.includes("Mac") && "ontouchend" in document);

const normalizedUserAgent = window.navigator.userAgent.toLowerCase();
const android = /android/.test(normalizedUserAgent);

export const flags = {
  ios,
  android,
  iframe: window.parent && window.parent !== window,
};
