import styled from "styled-components";

export const ErrorPage = styled.div`
  position: absolute;
  inset: 0;

  padding: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  font-size: 1.2rem;
`;
